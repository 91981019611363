import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Files
import { api } from '../constants/api';

// Context
import { IsLoggedInContext } from '../components/App/App';

const ConfirmTokenPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(IsLoggedInContext);

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      api
        .post(`confirmation-email/${token}/`, {})
        .then(({ data }) => {
          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);

          setTimeout(() => {
            setIsLoggedIn(true);
            navigate('/');
          }, 3000);
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            navigate('/404/');
          }
        });
    }
  }, []);

  return (
    <div className="container" style={{ minHeight: 'calc(100vh - 523px)' }}>
      <h1 className="page-title page-title--confirm-token">Адрес электронной почты успешно подтвержден</h1>
    </div>
  );
};

export default ConfirmTokenPage;
