import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// Files
import './FreezeAbonementPopup.scss';
import { api } from '../../../constants/api';
import { getFreezeEndDate, updBalance } from './index';

// Components
import ClosePopup from '../../../components/IconComponents/ClosePopup';

// Context
import { FreezeAbonementPopupContext } from '../AccountPage';

const FreezeAbonementPopup = () => {
  // Use context
  const {
    unFreezeEndDate,
    userAccountBalance,
    setUserAccountBalance,
    freezeAbonementId,
    isFrozenAbonementPopup,
    setIsFrozenAbonementPopup,
    isFrozenAbonement,
  } = useContext(FreezeAbonementPopupContext);

  const [freezeDuration, setFreezeDuration] = useState(null);
  const [freezeEndDate, setFreezeEndDate] = useState(null);
  const [availableAbonement, setAvailableAbonement] = useState(null);

  // при клике на заморозку абонемента получить его длительность заморозки
  useEffect(() => {
    if (freezeAbonementId) {
      api.post(`available_freeze_duration/${freezeAbonementId}/`).then(({ data }) => {
        const daysDuration = (data.seconds / 60 / 60 / 24).toFixed(); // время заморозки

        setFreezeEndDate(getFreezeEndDate(data.seconds)); // дата окончания заморозки
        setFreezeDuration(daysDuration); // установить время заморозки в попап
      });

      // Абонемент действителен до...
      const findAbonement = userAccountBalance.filter((item) => item.id === freezeAbonementId);
      const abonementAvailableDate = findAbonement[0]?.date_end.split('T')[0].split('-').reverse().join('.');

      setAvailableAbonement(abonementAvailableDate);
    }
  }, [freezeAbonementId]);

  /*** Handlers ***/
  const handleClose = () => setIsFrozenAbonementPopup(false);

  const handleFreeze = (id) => {
    api.post(`freeze_product_on/${id}/`).then(({ data }) => {
      setUserAccountBalance(updBalance(userAccountBalance, id));
    });
  };

  const handleUnFreeze = (id) => {
    api.post(`freeze_product_off/${id}/`).then(({ data }) => {
      setUserAccountBalance(updBalance(userAccountBalance, id));
    });
  };

  return (
    <Dialog
      classes={{ paper: 'dialog-popup' }}
      open={isFrozenAbonementPopup}
      onClose={handleClose}
      aria-labelledby="freeze-abonement-title"
      aria-describedby="freeze-abonement-description"
    >
      <button className="dialog-close" onClick={handleClose}>
        <ClosePopup />
      </button>

      <DialogTitle id="freeze-abonement-title" className="dialog-title">
        {!isFrozenAbonement ? 'заморозить' : 'разморозить'} абонемент
      </DialogTitle>

      <DialogContent id="freeze-abonement-description" className="dialog-content dialog-freeze-content">
        <div className="dialog-freeze-content__item">
          <div className="dialog-freeze-content__title">Срок действия</div>
          <div className="dialog-freeze-content__text">до {availableAbonement}</div>
        </div>

        <div className="dialog-freeze-content__item">
          <div className="dialog-freeze-content__title">Статус</div>

          <div className="dialog-freeze-content__text">
            {!isFrozenAbonement ? 'Активирован' : `Заморожен до ${unFreezeEndDate}`}
          </div>
        </div>

        {!isFrozenAbonement && (
          <div className="dialog-freeze-content__item">
            <div className="dialog-freeze-content__text">
              Заморозить на <span>{freezeDuration}</span> дней до {freezeEndDate}
            </div>
          </div>
        )}
      </DialogContent>

      <DialogActions className="dialog-actions">
        <button
          className="btn btn--bg"
          onClick={() => {
            !isFrozenAbonement ? handleFreeze(freezeAbonementId) : handleUnFreeze(freezeAbonementId);
            setIsFrozenAbonementPopup(false);
          }}
          autoFocus
        >
          {!isFrozenAbonement ? 'заморозить' : 'разморозить'}
        </button>

        <button className="btn" onClick={handleClose}>
          отмена
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default FreezeAbonementPopup;
