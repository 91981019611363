import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { api } from '../../../constants/api';
import './PasswordEmailRecoveryPage.scss';

function PasswordEmailRecoveryPage() {
  const navigate = useNavigate();

  function submitForm(event) {
    event.preventDefault(); // предотвращаем стандартное поведение формы
    const form = event.target; // получаем форму из события
    const formData = new FormData(form); // создаем объект FormData

    api
      .post('reset-password/', formData)
      .then((response) => {
        // обработка успешного ответа
        if (response.data.email) {
          toast.success('Проверочный код отправлен, проверьте свой почтовый ящик');
          navigate('/recovery-password-email/newpassword/');
          // переход на страницу восстановления  по email(ввод нового пароля)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.error('Пользователь с таким email не зарегистрирован');
        }
      });
  }
  // присваиваем функцию обработчика события submit формы
  const Form = () => {
    return (
      <div className="recovery-page">
        <div className="recovery-page__content wrapper">
          <h1 className="recovery-page__title">Восстановление пароля</h1>
          <h2 className="recovery-page__subtitle">забыли пароль?</h2>
          <form className="recovery-form" onSubmit={submitForm}>
            <label htmlFor="recovery-email">
              Введите свой адрес электронной почты ниже, и мы вышлем Вам инструкцию, как установить новый пароль
            </label>
            <input type="text" id="recovery-email" name="email" placeholder="Адрес электронной почты" />
            <button type="submit" className="recovery-form__btn">
              восстановить мой пароль
            </button>
          </form>
        </div>
      </div>
    );
  };

  return <Form />;
}
export default PasswordEmailRecoveryPage;
