import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { toast } from 'sonner';
import * as Yup from 'yup';
import logoImg from '../../../assets/images/logo.png';
import ClosePopup from '../../../components/IconComponents/ClosePopup';
import { api } from '../../../constants/api';
import './ChangePasswordPopUp.scss';

const ChangePasswordPopUp = ({ isChangePasswordPopUpOpen, setIsChangePasswordPopUpOpen }) => {
  const [error, setErrors] = useState(null);
  const handleClosePopup = () => {
    setIsChangePasswordPopUpOpen(false);
    setErrors(null);
  };
  const inputRef = useRef(null);
  const popUpRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const editPasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Минимум 8 символов').required('Обязательное поле'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
      .required('Обязательное поле'),
  });
  const handleSubmit = (values) => {
    api
      .patch('change-password/', values)
      .then(() => {
        setErrors(null);
        toast.success('Пароль успешно изменен');
        handleClosePopup();
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          setErrors(response.data.password[0]);
        } else {
          console.error(response);
        }
      });
  };
  return (
    <>
      <CSSTransition in={isChangePasswordPopUpOpen} timeout={300} nodeRef={popUpRef} classNames="popUp" unmountOnExit>
        <div ref={popUpRef} className="popup-change-password">
          <div className="popup-change-password__wrapper">
            <Link className="popup-change-password__logo" to="/account">
              <img src={logoImg} alt="logo" />
            </Link>

            <button className="popup-change-password__close" onClick={handleClosePopup}>
              <ClosePopup />
            </button>
            <Formik
              initialValues={{ password: '', password_confirmation: '' }}
              onSubmit={handleSubmit}
              validationSchema={editPasswordSchema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {() => {
                return (
                  <Form className="popup-change-pass-form">
                    <label className="popup__item">
                      <Field
                        onClick={() => setErrors(null)}
                        type="password"
                        name="password"
                        innerRef={inputRef}
                        placeholder="Введите новый пароль *"
                      />

                      <ErrorMessage component="div" className="invalid" name="password" />
                    </label>

                    <label className="popup-change-pass-form__item popup-change-pass-form__input-password">
                      <Field type="password" name="password_confirmation" placeholder="Повторите пароль *" />
                      <ErrorMessage component="div" className="invalid" name="password_confirmation" />
                    </label>
                    {error && <div className="invalid">{error}</div>}

                    <button type="submit" style={{ marginTop: 50 }} className="popup-change-pass-form__btn">
                      Изменить пароль
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default ChangePasswordPopUp;
