import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Files
import { api } from '../../constants/api';
import './Header.scss';
import headerLogo from './logo.png';

// Components
import BurgerIcon from '../IconComponents/BurgerIcon';
import InformerAbonementAngle from '../IconComponents/InformerAbonementAngle';
import InformerAbonementIcon from '../IconComponents/InformerAbonementIcon';
import LogoutIcon from '../IconComponents/LogoutIcon';
import UserIcon from '../IconComponents/UserIcon';
import Overlay from '../Overlay/Overlay';
import PopupAccount from '../PopupAccount/PopupAccount';
import PopupLogout from '../PopupLogout/PopupLogout';
import Social from '../Social/Social';
import DropdownMenu from './DropdownMenu/DropdownMenu';

// Context
import { AccountPopupContext, IsLoggedInContext, MenuAndIconsContext } from '../App/App';
import { Box, Button } from '@mui/material';

const Header = () => {
  const { isLoggedIn } = useContext(IsLoggedInContext); // Use user state context
  const { menu } = useContext(MenuAndIconsContext); // Use app menu context
  const { isPopupAccountOpen, setIsPopupAccountOpen, isPopupLogoutOpen, setIsPopupLogoutOpen } =
    useContext(AccountPopupContext); // Use app account popup context

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [abonementInformer, setAbonementInformer] = useState(''); // abonement informer text
  const [abonementInformerClass, setAbonementInformerClass] = useState(''); // abonement informer class

  // Пункты хедера с позицией "u" и без тренировок
  const headerMenu = menu.filter(({ position, children }) => position === 'u' && children.length === 0);

  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    if (isLoggedIn) {
      api.get('has-available-balance/').then(({ data }) => {
        let abonementInformerText;
        let abonementInformerClassName;

        if (data.available_balance) {
          abonementInformerText = 'Абонемент в наличии';
          abonementInformerClassName = 'is-abonement';
        } else {
          abonementInformerText = 'Купить абонемент';
          abonementInformerClassName = 'no-abonement';
        }

        setAbonementInformer(abonementInformerText);
        setAbonementInformerClass(abonementInformerClassName);
      });
    }
  }, [isLoggedIn]);

  /*** Handlers ***/
  const handleLogin = (e) => {
    e.preventDefault();

    accessToken ? navigate('/') : setIsPopupAccountOpen(!isPopupAccountOpen);
  };

  // попап выхода из аккаунта
  const handleLogout = (e) => {
    e.preventDefault();

    setIsPopupLogoutOpen(!isPopupLogoutOpen);
  };

  const handleOpenMenu = (e) => {
    e.preventDefault();

    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__content">
            {/*** Logo ***/}
            <div className="header__logo">
              <Link to="/">
                <img src={headerLogo} alt="logo" title="logo" />
              </Link>
            </div>
            {/*** Menu ***/}
            <nav className="header__nav">
              <ul className="header__list">
                <li className="header__item">
                  <DropdownMenu menu={menu} />
                </li>

                {headerMenu.map(({ id, slug, title }) => {
                  return (
                    <li className="header__item" key={id}>
                      <Link
                        to={slug}
                        onClick={(e) => {
                          // фикс бага при клике на меню "расписание" на стр расписания
                          if (window.location.pathname === slug) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>

            <div className="header__icons">
              {/*** Social link ***/}
              <Social />

              {/*** User abonement informer ***/}
              {isLoggedIn && (
                <div className={`abonement-informer ${abonementInformerClass}`}>
                  <div className="abonement-informer__icon abonement-informer__icon--left">
                    <InformerAbonementAngle />
                  </div>

                  <Link to="/payment/" className="abonement-informer__link">
                    <div className="abonement-informer__link-icon">
                      <InformerAbonementIcon />
                    </div>

                    <div className="abonement-informer__link-text">{abonementInformer}</div>
                  </Link>

                  <div className="abonement-informer__icon abonement-informer__icon--right">
                    <InformerAbonementAngle />
                  </div>
                </div>
              )}

              {/*** Login/Account ***/}
              {!isLoggedIn ? (
                <Link to="#" className="header__icon header__icon--login" onClick={(e) => handleLogin(e)}>
                  <UserIcon />
                </Link>
              ) : (
                <Link to="/account/" className="header__icon header__icon--account">
                  <UserIcon />
                </Link>
              )}

              {/*** Logout ***/}
              {isLoggedIn && (
                <Link to="#" className="header__icon header__icon--logout" onClick={(e) => handleLogout(e)}>
                  <LogoutIcon />
                </Link>
              )}

              {/*** Burger ***/}
              <Link to="#" className="header__icon header__icon--burger" onClick={(e) => handleOpenMenu(e)}>
                <BurgerIcon />
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/*** Mob menu ***/}
      <Overlay
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        abonementInformer={abonementInformer}
        abonementInformerClass={abonementInformerClass}
      />

      {/*** Login popup ***/}
      <PopupAccount />

      {/*** Logout popup ***/}
      <PopupLogout setIsMenuOpen={setIsMenuOpen} />
    </>
  );
};

export default Header;
