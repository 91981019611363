/*** Определение времени начала и конца занятия ***/
export const classTime = (date, duration) => {
  const classEndTimeMs = new Date(date).getTime() + duration * 60 * 1000; // Найти окончание тренировки в мс
  const classEndTimeStr = new Date(classEndTimeMs); // Окончание тренировки в виде строки
  const classEndTimeHours = classEndTimeStr.getHours().toString().padStart(2, '0'); // Окончание тренировки (ч)
  const classEndTimeMinutes = classEndTimeStr.getMinutes().toString().padStart(2, '0'); // Окончание тренировки (м)
  const classStartTime = date.split(/[T+]/)[1].slice(0, -3); // Найти подстроку со временем в date (часы и минуты)
  const classEndTime = `${classEndTimeHours}:${classEndTimeMinutes}`; // Время окончания тренировки

  return {
    startTime: classStartTime,
    endTime: classEndTime,
  };
};

/*** Заполненность занятия в виде кубиков ***/
export const filledProgress = (playersCount, limit) => {
  const filledProgress = []; // массив закрашенных кубиков для заполненности занятия
  const filledItem = Math.round((playersCount / limit) * 6); // Количество закрашенных кубиков с округлением
  let i = 1;

  // заполнить массив закрашенных кубиков
  while (i <= 6) {
    i <= filledItem ? filledProgress.push('filled') : filledProgress.push('');
    i++;
  }

  return filledProgress;
};
