import React from 'react';

const ClosePopup = () => {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="18.5" r="18.5" fill="#FAF62D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1204 11.1511L11.1505 14.121L16.0296 19L11.1505 23.879L14.1204 26.8489L18.9994 21.9698L23.8784 26.8489L26.8483 23.879L21.9693 19L26.8483 14.121L23.8784 11.1511L18.9994 16.0302L14.1204 11.1511Z"
        fill="black"
      />
    </svg>
  );
};

export default ClosePopup;
