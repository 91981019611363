import React from 'react';
import './ChooseRole.scss';

function ChooseRole({ roles, handleJoinClass }) {
  return (
    <>
      <div className="radio-group">
        {Object.entries(roles).map(([role, available]) => (
          <button className="btn btn--bg btn--octagon" onClick={() => handleJoinClass({ role })} disabled={!available}>
            {role}
          </button>
        ))}
      </div>
    </>
  );
}

export default ChooseRole;
