import React from 'react';

const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9995 15C15.9995 10.3056 19.8051 6.5 24.4995 6.5C29.1939 6.5 32.9995 10.3056 32.9995 15C32.9995 19.6944 29.1939 23.5 24.4995 23.5C19.8051 23.5 15.9995 19.6944 15.9995 15ZM24.4995 9.5C21.4619 9.5 18.9995 11.9624 18.9995 15C18.9995 18.0376 21.4619 20.5 24.4995 20.5C27.5371 20.5 29.9995 18.0376 29.9995 15C29.9995 11.9624 27.5371 9.5 24.4995 9.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5005 29.5C14.0152 29.5 12.0005 31.5148 12.0005 34V36.3766C12.0005 36.4128 12.0267 36.4436 12.0624 36.4494C20.2999 37.7944 28.7011 37.7944 36.9385 36.4494C36.9743 36.4436 37.0005 36.4128 37.0005 36.3766V34C37.0005 31.5148 34.9857 29.5 32.5005 29.5H31.8187C31.7661 29.5 31.7137 29.5084 31.6635 29.5246L29.9325 30.09C26.4029 31.2424 22.5981 31.2424 19.0684 30.09L17.3374 29.5246C17.2873 29.5084 17.2349 29.5 17.1822 29.5H16.5005ZM9.00049 34C9.00049 29.8578 12.3583 26.5 16.5005 26.5H17.1822C17.5512 26.5 17.9179 26.5584 18.2686 26.6728L19.9997 27.2382C22.9243 28.193 26.0767 28.193 29.0013 27.2382L30.7323 26.6728C31.0831 26.5584 31.4497 26.5 31.8187 26.5H32.5005C36.6427 26.5 40.0005 29.8578 40.0005 34V36.3766C40.0005 37.883 38.9087 39.1674 37.4219 39.4102C28.8643 40.8074 20.1367 40.8074 11.579 39.4102C10.0922 39.1674 9.00049 37.883 9.00049 36.3766V34Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserIcon;
