import React from 'react';

// Files
import './BalloonContent.scss';
import mapMarker from '../map-point.svg';

// Components
import Image from '../../Image/Image';

const BalloonContent = ({ data }) => {
  const { logo, name, description } = data;

  return (
    <div className="balloon-content">
      {/*<h3 className="balloon-content__title">контакты</h3>*/}

      <div className="balloon-content__address-block">
        <div className="balloon-content__image">
          {logo ? (
            <Image desk={logo} alt={'metro-icon'} title={'metro-icon'} />
          ) : (
            <img src={mapMarker} alt="metro-icon" title="metro-icon" />
          )}
        </div>

        <p className="balloon-content__address">{name}</p>
      </div>

      <p className="balloon-content__text">{description}</p>
    </div>
  );
};

export default BalloonContent;
