import { Input } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

// Files
import { api } from '../../../constants/api';
import { removeAuthStorage, toggleBodyScrollHandler } from '../../../constants/constants';
import './EditProfilePopup.scss';

// Components
import BirthDayMask from '../../../components/BirthDayMask';
import BallDecor from '../../../components/IconComponents/BallDecor';
import ClosePopup from '../../../components/IconComponents/ClosePopup';
import Image from '../../../components/Image/Image';
import PhoneMask from '../../../components/PhoneMask';

// Context
import { AccountPopupContext, IsLoggedInContext } from '../../../components/App/App';
import { EditProfilePopupContext } from '../AccountPage';

const EditProfilePopup = ({ userAccountData }) => {
  const navigate = useNavigate();

  const convertBirthday = userAccountData.birthday?.split('-').reverse().join('.') ?? 'Не указана';

  // Use context
  const { setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext); // Use app account popup context

  const [uploadedAvatar, setUploadedAvatar] = useState(null); // State for uploaded image in edit profile form
  const [roleList, setRoleList] = useState([]); // State for all role
  const [errors, setErrors] = useState({}); // Errors
  const [birthDayValue, setBirthDayValue] = useState(convertBirthday); // значение поля с маской даты рождения
  const [phoneValue, setPhoneValue] = useState(userAccountData.phone); // значение поля с маской телефона

  // State for all converted role list in select
  const [selectRoleOptions, setSelectRoleOptions] = useState([]);

  // State for converted initial user role by default in select
  const [defaultUserRole, setDefaultUserRole] = useState([]);

  // State for selected id role in edit profile form
  const [selectedRoleId, setSelectedRoleId] = useState([]);

  // Get initial data for form
  const userEditProfileData = {
    first_name: userAccountData.first_name || '',
    last_name: userAccountData.last_name || '',
    birthday: userAccountData.birthday,
    height: userAccountData.height || '',
    experience: userAccountData.experience || '',
    telegram: userAccountData.telegram || '',
    phone: userAccountData.phone || '',
    role: userAccountData.role,
    about: userAccountData.about || '',
  };

  // Use user edit profile state context
  const {
    avatar,
    setUserAccountData,
    isEditProfilePopupOpen,
    setIsEditProfilePopupOpen,
    isEditProfileForm,
    setIsEditProfileForm,
  } = useContext(EditProfilePopupContext);

  // Get all roles
  useEffect(() => {
    api
      .get('role/')
      .then(({ data }) => {
        const selectOptionsArray = []; // Create array for select role
        const defaultUserRoleArray = []; // Create array for user default role

        // Convert role array for select
        data.map(({ id, name }) => {
          selectOptionsArray.push({ id: id, value: name, label: name });
        });

        // Convert default user role array for select as initial role
        userEditProfileData.role.map(({ id, name }) => {
          defaultUserRoleArray.push({ id: id, value: name, label: name });
        });

        setRoleList(data); // Set role list
        setSelectRoleOptions(selectOptionsArray); // Set converted select array
        setDefaultUserRole(defaultUserRoleArray); // Set converted default use role
      })
      .catch((error) => console.log(error, 'error'));
  }, []);

  /*** Handlers ***/
  // Close popup
  const closePopupHandler = () => {
    setIsEditProfilePopupOpen(false);
    setErrors({});

    toggleBodyScrollHandler();

    setTimeout(() => setIsEditProfileForm(false), 250);
  };

  // Upload avatar
  const uploadImageHandler = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => setUploadedAvatar(reader.result);
    reader.onerror = (error) => console.log('Error: ', error);
  };

  // Select role
  const selectRoleHandler = (el) => {
    const selectedRoleIdArray = el.reduce((accum, item) => [...accum, item.id], []);

    setSelectedRoleId(selectedRoleIdArray);
  };

  return (
    <div className={`popup-wrapper edit-profile-popup-wrapper ${isEditProfilePopupOpen ? 'open' : ''}`}>
      <div className="popup edit-profile-popup">
        <h3 className="popup__title">Личные данные</h3>
        <div className="popup__content">
          {isEditProfileForm && (
            <Formik
              initialValues={userEditProfileData}
              onSubmit={(values) => {
                const body = {};
                // удаление спецсимволов из тлф
                const formattedPhone = phoneValue.replace(/[^+\d]/gm, '');

                Object.entries(values).forEach(([key, value]) => (body[key] = value));

                body['birthday'] = birthDayValue;
                body['phone'] = formattedPhone;

                if (uploadedAvatar) {
                  body['avatar'] = uploadedAvatar;
                }

                // Добавляем выбранные роли в body или пустой массив если ничего не выбрано
                selectedRoleId.length > 0
                  ? (body.role = roleList.filter(({ id }) => selectedRoleId.includes(id)))
                  : (body.role = []);

                api
                  .patch('profile/', body)
                  .then(({ data }) => {
                    setUserAccountData(data);
                    console.log(body);

                    const newDefaultUserRole = [];

                    // Convert new default user role array for select as initial role
                    data.role.map(({ id, name }) => {
                      newDefaultUserRole.push({ id: id, value: name, label: name });
                    });

                    setDefaultUserRole(newDefaultUserRole);

                    closePopupHandler();
                  })
                  .catch(({ response }) => {
                    if (response.status === 400) {
                      if (body.first_name === '') {
                        response.data.first_name = 'Поле не должно быть пустым';
                        setErrors(response.data);
                      } else {
                        setErrors(response.data);
                      }

                      if (body.last_name === '') {
                        response.data.last_name = 'Поле не должно быть пустым';
                        setErrors(response.data);
                      } else {
                        setErrors(response.data);
                      }

                      if (body.birthday === '') {
                        response.data.birthday = 'Поле не должно быть пустым';
                        setErrors(response.data);
                      } else {
                        setErrors(response.data);
                      }

                      if (body.experience === '') {
                        response.data.experience = 'Поле не должно быть пустым';
                        setErrors(response.data);
                      } else {
                        setErrors(response.data);
                      }

                      if (body.height === '') {
                        response.data.height = 'Поле не должно быть пустым';
                        setErrors(response.data);
                      } else {
                        setErrors(response.data);
                      }

                      if (body.phone === '') {
                        response.data.phone = 'Поле не должно быть пустым';
                        setErrors(response.data);
                      } else {
                        setErrors(response.data);
                      }
                    }

                    if (response.status === 401) {
                      // Logout user and redirect to main page and show login popup
                      if (response.status === 401) {
                        removeAuthStorage();

                        setIsLoggedIn(false); // Logout user
                        navigate('/'); // Redirect to main page
                        setIsPopupAccountOpen(true); // Show login popup
                      }
                    }
                  });
              }}
            >
              <Form className="form edit-profile-form">
                <div className="form__body">
                  {/*** Avatar ***/}
                  <div className="form__group form__group--avatar">
                    <div className="avatar">
                      {avatar ? <Image desk={avatar} alt={avatar} title={avatar} /> : <BallDecor />}
                    </div>

                    <span className="form__label-name">Фото профиля</span>

                    <div className="form-file">
                      <button className="btn btn--bg form-file__btn">
                        <span className="form-file__title">загрузить</span>
                      </button>

                      <Field
                        id="avatar"
                        type="file"
                        name="upload-avatar"
                        onChange={(e) => uploadImageHandler(e)}
                        className="form-file__input"
                        placeholder="avatar"
                      />
                    </div>
                  </div>

                  {/*** User name ***/}
                  <div className="form__group form__group--name">
                    <label className="form__label">
                      <Field
                        id="first_name"
                        type="text"
                        name="first_name"
                        className="form__input"
                        placeholder="Введите имя"
                      />
                      <span className="form__label-name">
                        Имя <span className="required">*</span>
                        {errors.first_name && <span className="error-msg">{errors.first_name}</span>}
                      </span>
                    </label>
                  </div>

                  {/*** User last name ***/}
                  <div className="form__group form__group--surname">
                    <label className="form__label">
                      <Field
                        id="last_name"
                        type="text"
                        name="last_name"
                        className="form__input"
                        placeholder="Введите фамилию"
                      />
                      <span className="form__label-name">
                        Фамилию <span className="required">*</span>
                        {errors.last_name && <span className="error-msg">{errors.last_name}</span>}
                      </span>
                    </label>
                  </div>

                  {/*** User birthday ***/}
                  <div className="form__group form__group--birthday">
                    <label className="form__label">
                      <Input
                        id="birthday"
                        type="text"
                        name="birthday"
                        placeholder="Дата рождения"
                        className="form__input"
                        value={birthDayValue}
                        onChange={(event) => setBirthDayValue(event.target.value)}
                        inputComponent={BirthDayMask}
                      />

                      <span className="form__label-name">
                        Дата рождения <span className="required">*</span>
                        {errors.birthday && (
                          <span className="error-msg" style={{ maxWidth: '66%' }}>
                            {errors.birthday}
                          </span>
                        )}
                      </span>
                    </label>
                  </div>

                  {/*** User height ***/}
                  <div className="form__group form__group--height">
                    <label className="form__label">
                      <Field id="height" type="text" name="height" className="form__input" placeholder="Рост, см" />
                      <span className="form__label-name">
                        Рост, см <span className="required">*</span>
                        {errors.height && <span className="error-msg">{errors.height}</span>}
                      </span>
                    </label>
                  </div>

                  {/*** User experience ***/}
                  <div className="form__group form__group--experience">
                    <label className="form__label">
                      <Field
                        id="experience"
                        type="text"
                        name="experience"
                        className="form__input"
                        placeholder="Опыт, лет"
                      />
                      <span className="form__label-name">
                        Опыт, лет <span className="required">*</span>
                        {errors.experience && (
                          <span className="error-msg" style={{ maxWidth: '80%' }}>
                            {errors.experience}
                          </span>
                        )}
                      </span>
                    </label>
                  </div>

                  {/*** User telegram ***/}
                  <div className="form__group form__group--telegram">
                    <label className="form__label">
                      <Field
                        id="telegram"
                        type="text"
                        name="telegram"
                        className="form__input"
                        placeholder="https://t.me/User или @User"
                      />
                      <span className="form__label-name">Телеграмм</span>
                    </label>
                  </div>

                  {/*** User phone ***/}
                  <div className="form__group form__group--phone">
                    <label className="form__label">
                      <Input
                        id="phone"
                        type="text"
                        name="phone"
                        placeholder="Телефон"
                        className="form__input"
                        value={phoneValue}
                        onChange={(event) => setPhoneValue(event.target.value)}
                        inputComponent={PhoneMask}
                      />

                      <span className="form__label-name">
                        Телефон <span className="required">*</span>
                        {errors.phone && <span className="error-msg">{errors.phone}</span>}
                      </span>
                    </label>
                  </div>

                  {/*** User role ***/}
                  <div className="form__group form__group--role">
                    <label className="form__label">
                      <Select
                        defaultValue={defaultUserRole}
                        isMulti
                        name="role"
                        options={selectRoleOptions}
                        placeholder={'Роль не выбрана'}
                        className="edit-profile-form__select"
                        classNamePrefix="select"
                        onChange={(el) => selectRoleHandler(el)}
                      />
                      <span className="form__label-name">Роль</span>
                    </label>
                  </div>

                  {/*** User about ***/}
                  <div className="form__group form__group--about">
                    <label className="form__label">
                      <Field
                        id="about"
                        name="about"
                        component="textarea"
                        className="form__textarea"
                        placeholder="О себе"
                      />
                      <span className="form__label-name">О себе</span>
                    </label>
                  </div>
                </div>

                <div className="form__footer">
                  <button type="submit" className="btn btn--bg form__submit">
                    Сохранить
                  </button>
                </div>
              </Form>
            </Formik>
          )}
        </div>

        <button className="popup__close" onClick={closePopupHandler}>
          <ClosePopup />
        </button>
      </div>
    </div>
  );
};

export default EditProfilePopup;
