import React from 'react';
import { Link } from 'react-router-dom';

const PaymentFailedPage = () => {
  const backToMainStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 45,
    maxWidth: 250,
    fontFamily: '"Docker", "Helvetica", "Arial", sans-serif',
  };

  return (
    <div className="container" style={{ minHeight: 'calc(100vh - 523px)' }}>
      <h1 className="page-title page-title--confirm-token">Произошла ошибка, оплатить не удалось</h1>

      <Link className="btn btn--bg" to="/" style={{ ...backToMainStyle }}>
        На главную
      </Link>
    </div>
  );
};

export default PaymentFailedPage;
