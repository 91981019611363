import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Files
import './Confirmation.scss';
import { api } from '../../../constants/api';

// Context
import { IsLoggedInContext } from '../../App/App';

function ConfirmationEmailPage() {
  const { setIsLoggedIn } = useContext(IsLoggedInContext);
  const navigate = useNavigate();

  const [errors, setErrors] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) navigate('/');
  }, [navigate]);

  function submitForm(event) {
    event.preventDefault(); // предотвращаем стандартное поведение формы

    const form = event.target; // получаем форму из события
    const formData = new FormData(form); // создаем объект FormData

    api
      .post('confirmation/', formData)
      .then((response) => {
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);

        if (response.status === 201) {
          setSuccessMsg('Почта успешно подтверждена');
          setIsLoggedIn(true);

          setTimeout(() => navigate('/schedule/'), 2000);
        }
      })
      .catch(({ response }) => {
        // Если есть ошибки валидации, отображаем их на странице
        if (response.status === 404) {
          setErrors('Неверный код');
        }
      });
  }

  // присваиваем функцию обработчика события submit формы
  const Form = () => {
    const errorActive = errors ? 'error_active' : '';
    const successActive = successMsg ? 'success_active' : '';

    return (
      <div className="confirmation-page">
        <div className="confirmation-page__content wrapper">
          <h1 className="confirmation-page__title">Регистрация</h1>
          <h2 className="confirmation-page__subtitle">Подтверждение почты</h2>
          <form className="confirmation-form" onSubmit={submitForm}>
            <label htmlFor="confirmation-email">
              На указанную почту было выслано письмо с кодом (если его нет во входящих, проверьте спам). Введите код из
              письма в строке ниже.
            </label>

            <input
              id="confirmation-email"
              type="text"
              name="confirmation_token"
              placeholder="Проверочный код из письма"
            />

            <div className={`error ${errorActive} ${successActive}`}>{errors}</div>

            <button type="submit" className="confirmation-form__btn btn btn--bg">
              Подтвердить
            </button>
          </form>
        </div>
      </div>
    );
  };

  return <Form />;
}
export default ConfirmationEmailPage;
