/*** Получение диапазона дней в неделе ***/
// Неделя с индексом смещения дней относительно середины недели
const week = [
  ['Понедельник', 'Пн', -3],
  ['Вторник', 'Вт', -2],
  ['Среда', 'Ср', -1],
  ['Четверг', 'Чт', 0],
  ['Пятница', 'Пт', 1],
  ['Суббота', 'Cб', 2],
  ['Воскресенье', 'Вс', 3],
];
const monthList = [
  ['Январь', 'Янв'],
  ['Февраль', 'Фев'],
  ['Март', 'Мар'],
  ['Апрель', 'Апр'],
  ['Май', 'Мая'],
  ['Июнь', 'Июн'],
  ['Июль', 'Июл'],
  ['Август', 'Авг'],
  ['Сентябрь', 'Сен'],
  ['Октябрь', 'Окт'],
  ['Ноябрь', 'Ноя'],
  ['Декабрь', 'Дек'],
];

const date = new Date();
const convertDayToMilliSec = 24 * 60 * 60 * 1000; // день в милисек
const now = Date.now(); // сегодняшний день в милисек
let dayNumb = date.getDay(); // индекс сегодняшнего дня 0-6

dayNumb === 0 ? (dayNumb = 6) : dayNumb--; // Поменять индекс дня недели, чтоб начинался с Пн

const today = week[dayNumb]; // сегодняшний день достаем из week по новому индексу

// Функция определения диапазона недели
export const weekRange = (dayRange = 0) => {
  // середина текущей недели в милисек. Вычесть индекс смещения для текущего дня и добавить смещение (dayRange)
  const middleWeek = now - today[2] * convertDayToMilliSec + dayRange * convertDayToMilliSec;

  // результат недели
  return week.map(([dayName, dayNameShort, shift], index) => {
    const dayInWeek = new Date(middleWeek + shift * convertDayToMilliSec); // дни недели в милисек
    const day = dayInWeek.getDate().toString().padStart(2, '0');
    const month = (dayInWeek.getMonth() + 1).toString().padStart(2, '0');
    const monthName = monthList[dayInWeek.getMonth()][0];
    const monthNameShort = monthList[dayInWeek.getMonth()][1];
    const year = dayInWeek.getFullYear();

    return {
      id: index + 1,
      day: day,
      dayName: dayName,
      dayNameShort: dayNameShort,
      month: month,
      monthName: monthName,
      monthNameShort: monthNameShort,
      year: year,
    };
  });
};

/*** Создание новой структуры данных всех занятий ***/
export const createScheduleDataObj = (data) => {
  const scheduleDataObj = {}; // create new schedule obj

  data.forEach((item) => {
    let itemWeekDayIndex = new Date(item.date).getDay(); // получение индекса расположения занятия в неделе
    itemWeekDayIndex === 0 ? (itemWeekDayIndex = 6) : itemWeekDayIndex--; // его позиция в массиве занятий

    item.ended = false; // добавить обозначение оконченного занятия по умолчанию

    // отметить прошеднее занятие по дню недели
    // по времени пока отметки нет
    if (itemWeekDayIndex < dayNumb) {
      item.ended = true;
    }

    // формирование новой структуры данных
    if (!scheduleDataObj[item.gym.title]) {
      scheduleDataObj[item.gym.title] = {
        classes: [[], [], [], [], [], [], []],
        color: item.gym.color,
        slug: item.gym.slug,
      };

      scheduleDataObj[item.gym.title].classes[itemWeekDayIndex] = [item];
    } else {
      scheduleDataObj[item.gym.title].classes[itemWeekDayIndex].push(item);
    }
  });

  return scheduleDataObj;
};
