/*** Remove authenticated user info from local storage ***/
export const removeAuthStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
};

/*** Price convert ***/
export const priceConvertHandler = (price) => {
  if (price) {
    return price.slice(0, -3);
  }
};

/*** Random sort array ***/
export const shuffle = (array) => {
  let m = array.length,
    t,
    i;

  while (m) {
    i = Math.floor(Math.random() * m--);

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
};

/*** Format date ***/
// For account page
export const formatDate = (date) => {
  // Bug fix for FF. For FF format date should be str with ',' instead '.'
  if (date) {
    const convertedDate = date.split('.').join(',');
    const newDate = new Date(convertedDate);
    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear();

    return `${day}.${month}.${year}`;
  } else {
    return 'Не указана';
  }
};

// For reviews page
export const formatDateReviews = (date) => {
  // Bug fix for safari. For safari format date should be str with '/' instead '.'
  const convertedDate = date.split('T')[0].split('-').join('/');
  const newDate = new Date(convertedDate);
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();

  return `${day}.${month}.${year}`;
};

/*** Toggle no-scroll class when popup open/close ***/
export const toggleBodyScrollHandler = () => {
  const body = document.querySelector('body');

  body.classList.toggle('no-scroll');
};

/*** Convert hex color to rgb/rgba ***/
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

/*** Разделение длинного текста на два параграфа ***/
export const descriptionDivision = (description, maxLength, separation) => {
  if (description) {
    if (!maxLength) {
      maxLength = 200;
    }
    const text = description;

    let firstParagraph = '';
    let secondParagraph = '';
    const cleanedText = text.replace(/\r|\n/g, '');

    let currentLength = 0;
    let fullnessFirstParagraph = false;

    let splits;
    if (separation === 'sentence') {
      splits = cleanedText.match(/[^.!?]+[.!?]+/g);
    } else {
      splits = cleanedText.match(/\S+|[.,!?;]+/g);
    }

    for (const split of splits) {
      if (!fullnessFirstParagraph && currentLength + split.length <= maxLength) {
        if (currentLength > 0) {
          firstParagraph += ' ';
        }
        firstParagraph += split;
        currentLength += split.length;
      } else {
        if (!fullnessFirstParagraph) {
          fullnessFirstParagraph = true;
        }
        if (currentLength > 0) {
          secondParagraph += ' ';
        }
        secondParagraph += split;
      }
    }
    return [firstParagraph, secondParagraph];
  } else return null;
};
