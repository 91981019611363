import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Files
import './StaticPage.scss';
import { api } from '../../constants/api';

// Components
import Image from '../Image/Image';

function StaticPage() {
  const { slug } = useParams();
  const [staticPage, setStaticPage] = useState([]);

  useEffect(() => {
    api
      .get(`static-page/${slug}/`)
      .then((response) => setStaticPage(response.data))
      .catch((error) => console.error(error));
  }, [slug]);

  return (
    <div className="container">
      <section className="static-page">
        <h1 className="static-page__title">{staticPage.title}</h1>

        {staticPage.image && <Image desk={staticPage.image} alt={'static'} title={'static'} />}

        <div className="static-page__content" dangerouslySetInnerHTML={{ __html: staticPage.content }} />
      </section>
    </div>
  );
}

export default StaticPage;
