import { useField } from 'formik';
import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

const PhoneMask = (props, ref) => {
  const { handleChange, ...other } = props;
  const [field] = useField(props);

  return (
    <IMaskInput
      {...field}
      {...other}
      mask="+7 (900) 000-00-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={handleChange}
      overwrite
    />
  );
};

export default forwardRef(PhoneMask);
