import React from 'react';

const BurgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
      <rect x="7" y="10" width="26" height="3" rx="1.5" fill="currentColor" />
      <rect x="7" y="18" width="26" height="3" rx="1.5" fill="currentColor" />
      <rect x="7" y="26" width="26" height="3" rx="1.5" fill="currentColor" />
    </svg>
  );
};

export default BurgerIcon;
