import React from 'react';
import { Link } from 'react-router-dom';

// Files
import './InfoCards.scss';

// Components
import Image from '../../../components/Image/Image';

const InfoCards = ({ data }) => {
  const cardOne =
    data?.first_link_block_title ||
    data?.first_link_block_url ||
    data?.first_link_block_image ||
    data?.first_link_block_image_mob;

  const cardSecond =
    data?.second_link_block_title ||
    data?.second_link_block_url ||
    data?.second_link_block_image ||
    data?.second_link_block_image_mob;

  return (
    <div className="info-cards">
      {cardOne && (
        <div className="info-cards__item info-card">
          <Link className="info-card__link" to={data?.first_link_block_url}>
            <div className="info-card__image">
              <Image mob={data?.first_link_block_image_mob} desk={data?.first_link_block_image} alt="card one" />
            </div>

            <div className="info-card__content">
              <h2 className="info-card__title">{data?.first_link_block_title}</h2>
            </div>
          </Link>
        </div>
      )}

      {cardSecond && (
        <div className="info-cards__item info-card">
          <Link className="info-card__link" to={data?.second_link_block_url}>
            <div className="info-card__image">
              <Image mob={data?.second_link_block_image_mob} desk={data?.second_link_block_image} alt="card two" />
            </div>

            <div className="info-card__content">
              <h2 className="info-card__title">{data?.second_link_block_title}</h2>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default InfoCards;
