import { Menu, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

// Files
import { api } from '../../../constants/api';
import { createScheduleDataObj } from '../index';
import './ScheduleFilter.scss';

// Components
import DropDownIcon from '../../../components/IconComponents/DropDownIcon';

// Context
import { MenuFilterContext } from '../SchedulePage';

const ScheduleFilter = () => {
  // Get data for menu filter from context
  const { trainers, gym, level, trainingType, filterParams, setFilterParams, setNoClassMsg, setScheduleInfo } =
    useContext(MenuFilterContext);

  // Pseudo api data for gender
  const gender = [
    { id: 1, title: 'a', name: 'Общий' },
    { id: 2, title: 'm', name: 'Мужской' },
    { id: 3, title: 'f', name: 'Женский' },
  ];

  /*** Filter menu states ***/
  // Reset filter button
  const [isResetFilterBtn, setIsResetFilterBtn] = useState(false);

  // Selected
  const [gymSelected, setGymSelected] = useState([]);
  const [typeSelected, setTypeSelected] = useState([]);
  const [trainerSelected, setTrainerSelected] = useState([]);
  const [levelSelected, setLevelSelected] = useState([]);

  // Trainer menu and active trainer filter state
  const [trainersMenu, setTrainersMenu] = useState(null);
  const [activeTrainerFilter, setActiveTrainerFilter] = useState('тренер');

  // Gym menu and active gym filter state
  const [gymMenu, setGymMenu] = useState(null);
  const [activeGymFilter, setActiveGymFilter] = useState('зал');

  // Level menu and active level filter state
  const [levelMenu, setLevelMenu] = useState(null);
  const [activeLevelFilter, setActiveLevelFilter] = useState('уровень');

  // Training menu and active training type filter state
  const [trainingTypeMenu, setTrainingTypeMenu] = useState(null);
  const [activeTrainingTypeFilter, setActiveTrainingTypeFilter] = useState('тип тренировки');

  // Gender menu and active gender filter state
  const [genderMenu, setGenderMenu] = useState(null);
  const [activeGenderFilter, setActiveGenderFilter] = useState('гендер тренировки');

  useEffect(() => {
    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    // Hide reset filter btn
    Object.keys(newParamsObj).length <= 2 ? setIsResetFilterBtn(false) : setIsResetFilterBtn(true);

    // Show reset btn after redirect from trainers page
    setTimeout(() => {
      // mark selected gyms
      if (newParamsObj.gym__in) setGymSelected(newParamsObj.gym__in.split(',').map((item) => +item));

      //  mark selected trainings type
      if (newParamsObj.type__in) setTypeSelected(newParamsObj.type__in.split(',').map((item) => +item));

      //  mark selected trainers
      if (newParamsObj.trainer__in) setTrainerSelected(newParamsObj.trainer__in.split(',').map((item) => +item));

      //  mark selected level
      if (newParamsObj.level__in) setLevelSelected(newParamsObj.level__in.split(',').map((item) => +item));

      if (Object.keys(newParamsObj).length === 1) {
        // Исправлено отсутствие кнопки очистить фильтр

        setIsResetFilterBtn(true); // Show reset filter btn

        // Set active trainer in filter menu
        setTrainerSelected((prevState) => {
          prevState.push(+newParamsObj.trainer__in);

          return prevState;
        });
      }
    }, 0);
  }, []);

  // Set selected params to filter menu on page load
  useEffect(() => {
    // Set trainer name if it's in params
    if (filterParams.has('trainer') && trainers.length > 0) {
      const paramValue = filterParams.get('trainer');
      const activeTrainerObject = trainers.filter(({ id }) => +id === +paramValue)[0];

      setActiveTrainerFilter(`${activeTrainerObject.last_name} ${activeTrainerObject.first_name}`);
    }

    // Set gym name if it's in params
    if (filterParams.has('gym') && gym.length > 0) {
      const paramValue = filterParams.get('gym');
      const activeGymObject = gym.filter(({ id }) => +id === +paramValue)[0];

      setActiveGymFilter(activeGymObject.name);
    }

    // Set level name if it's in params
    if (filterParams.has('level') && level.length > 0) {
      const paramValue = filterParams.get('level');
      const activeLevelObject = level.filter(({ id }) => +id === +paramValue)[0];

      setActiveLevelFilter(activeLevelObject.title);
    }

    // Set training-type name if it's in params
    if (filterParams.has('type') && trainingType.length > 0) {
      const paramValue = filterParams.get('type');
      const activeTrainingTypeObject = trainingType.filter(({ id }) => +id === +paramValue)[0];

      setActiveTrainingTypeFilter(activeTrainingTypeObject.title);
    }

    // Set gender name if it's in params
    if (filterParams.has('gender')) {
      const paramValue = filterParams.get('gender');
      const activeGenderObject = gender.filter(({ title }) => title === paramValue)[0];

      setActiveGenderFilter(activeGenderObject.name);
    }
  }, [trainers, gym, level, trainingType]);

  /*** Handlers ***/
  // Close all previous opened menu
  const handleClosePreviousMenu = () => {
    setTrainersMenu(null);
    setGymMenu(null);
    setLevelMenu(null);
    setTrainingTypeMenu(null);
    setGenderMenu(null);
  };

  // Получение отфильтрованных занятий
  const addFilterHandler = (filterType, id, genderTitle) => {
    // mark several selected gym
    if (filterType === 'gym__in') {
      setGymSelected((prevState) => {
        if (prevState.includes(id)) {
          return prevState.filter((item) => item !== id);
        }

        prevState.push(id);

        return prevState;
      });
    }

    // mark several selected type training
    if (filterType === 'type__in') {
      setTypeSelected((prevState) => {
        if (prevState.includes(id)) {
          return prevState.filter((item) => item !== id);
        }

        prevState.push(id);

        return prevState;
      });
    }

    // mark several selected trainer
    if (filterType === 'trainer__in') {
      setTrainerSelected((prevState) => {
        if (prevState.includes(id)) {
          return prevState.filter((item) => item !== id);
        }

        prevState.push(id);

        return prevState;
      });
    }

    // mark several selected level
    if (filterType === 'level__in') {
      setLevelSelected((prevState) => {
        if (prevState.includes(id)) {
          return prevState.filter((item) => item !== id);
        }

        prevState.push(id);

        return prevState;
      });
    }

    setIsResetFilterBtn(true); // Show reset filter btn

    // Если параметр этого фильтра с таким значением уже есть, то не делать никаких запросов
    if (filterParams.has(filterType) && filterParams.get(filterType).split(',').includes(id.toString())) {
      const filterTypeValues = filterParams.get(filterType).split(',');
      const we = filterTypeValues.filter((item) => +item !== id);

      const newParamsObj = {}; // Создать новый объект с параметрами
      // Взять существующие параметры и сформировать новый объект
      for (let [key, value] of filterParams.entries()) {
        if (key === filterType) {
          if (we.length > 0) {
            newParamsObj[key] = we.join(',');
          } else {
            if (filterType === 'gym__in') {
              setActiveGymFilter('зал');
            }
            if (filterType === 'type__in') {
              setActiveTrainingTypeFilter('тип тренировки');
            }
            if (filterType === 'trainer__in') {
              setActiveTrainerFilter('тренер');
            }
            if (filterType === 'level__in') {
              setActiveLevelFilter('уровень');
            }
          }
        } else {
          newParamsObj[key] = value;
        }
      }

      // Сформировать строку для запроса
      const paramsString = Object.entries(newParamsObj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      // Hide reset filter btn
      if (
        !Object.keys(newParamsObj).includes('gym__in') &&
        !Object.keys(newParamsObj).includes('type__in') &&
        !Object.keys(newParamsObj).includes('trainer__in') &&
        !Object.keys(newParamsObj).includes('level__in')
      ) {
        setIsResetFilterBtn(false);
      }

      setFilterParams(newParamsObj); // Установить параметры в адресную строку

      // Запрос для фильтрации
      api.get(`klass/?${paramsString}`).then(({ data }) => {
        const filteredClass = data.filter(({ is_published }) => is_published === true);

        data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

        setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
      });
      return;
    }

    const newParamsObj = {}; // Создать новый объект с параметрами

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    if (newParamsObj[filterType]) {
      newParamsObj[filterType] = `${newParamsObj[filterType]},${id}`;
    } else {
      newParamsObj[filterType] = id; // Добавить новый параметр в объекты фильтра
    }

    // Сформировать строку для запроса
    const paramsString = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    setFilterParams(newParamsObj); // Установить параметры в адресную строку

    // Запрос для фильтрации
    api.get(`klass/?${paramsString}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };

  // Получение отфильтрованных занятий после удаления параметра (пункт ---Все---)
  const deleteFilterHandler = (deleteParam) => {
    // Удаление параметра
    filterParams.delete(deleteParam);

    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    // Hide reset filter btn
    if (Object.keys(newParamsObj).length === 2) {
      setIsResetFilterBtn(false);
    }

    // Сформировать строку для запроса
    const paramsString = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    // Установить параметры в адресную строку
    setFilterParams(newParamsObj);

    // Запрос для фильтрации
    api.get(`klass/?${paramsString}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };

  // Сброс фильтров
  const resetFilter = () => {
    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      if (key === 'start_date' || key === 'end_date') newParamsObj[key] = value;
    }

    // Сформировать строку для запроса
    const paramsString = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    setFilterParams(newParamsObj); // Установить параметры в адресную строку
    setActiveLevelFilter('уровень'); // Set default level value
    setActiveTrainerFilter('тренер'); // Set default trainer value
    setActiveTrainingTypeFilter('тип тренировки'); // Set default training type value
    setActiveGymFilter('зал'); // Set default gym value
    setIsResetFilterBtn(false); // Hide reset filter button

    // Запрос для фильтрации
    api.get(`klass/?${paramsString}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };

  return (
    <>
      {/* Filter list */}
      <ul className="schedule-main-filter">
        {/* Gym */}
        <li className="schedule-main-filter__item">
          <span
            className={`schedule-main-filter__title ${gymMenu ? 'expanded' : ''}`}
            aria-controls={gymMenu ? 'gym-menu-filter' : undefined}
            aria-haspopup="true"
            onClick={(event) => {
              handleClosePreviousMenu();
              setGymMenu(event.currentTarget);
            }}
          >
            {activeGymFilter}
            <DropDownIcon />
          </span>

          <Menu
            id="gym-menu-filter"
            classes={{ paper: 'dropdown-menu' }}
            anchorEl={gymMenu}
            open={!!gymMenu}
            onClose={handleClosePreviousMenu}
          >
            <MenuItem
              onClick={() => {
                // Если параметра этого фильтра нет, то не делать никаких запросов
                if (!filterParams.has('gym__in')) {
                  handleClosePreviousMenu();
                  return;
                }

                setActiveGymFilter('зал'); // Set default value
                deleteFilterHandler('gym__in'); // Delete param
                handleClosePreviousMenu();
              }}
            >
              --- Все ---
            </MenuItem>

            {gym.map(({ id, name }) => {
              return (
                <MenuItem
                  key={id}
                  className={`${gymSelected?.includes(id) ? 'selected' : ''} `}
                  onClick={() => {
                    addFilterHandler('gym__in', id); // Add filter param
                    // setActiveGymFilter(name); // Set selected name
                  }}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Menu>
        </li>

        {/* Training type */}
        <li className="schedule-main-filter__item">
          <span
            className={`schedule-main-filter__title ${trainingTypeMenu ? 'expanded' : ''}`}
            aria-controls={trainingTypeMenu ? 'training-type-menu-filter' : undefined}
            aria-haspopup="true"
            onClick={(event) => {
              handleClosePreviousMenu();
              setTrainingTypeMenu(event.currentTarget);
            }}
          >
            {activeTrainingTypeFilter}
            <DropDownIcon />
          </span>

          <Menu
            id="training-type-menu-filter"
            classes={{ paper: 'dropdown-menu' }}
            anchorEl={trainingTypeMenu}
            open={!!trainingTypeMenu}
            onClose={handleClosePreviousMenu}
          >
            <MenuItem
              onClick={() => {
                // Если параметра этого фильтра нет, то не делать никаких запросов
                if (!filterParams.has('type__in')) {
                  handleClosePreviousMenu();
                  return;
                }

                setActiveTrainingTypeFilter('тип тренировки'); // Set default value
                deleteFilterHandler('type__in'); // Delete param
                handleClosePreviousMenu();
              }}
            >
              --- Все ---
            </MenuItem>

            {trainingType.map(({ id, title }) => {
              return (
                <MenuItem
                  key={id}
                  className={`${typeSelected?.includes(id) ? 'selected' : ''} `}
                  onClick={() => {
                    addFilterHandler('type__in', id); // Add filter param
                    // setActiveTrainingTypeFilter(title); // Set selected name
                  }}
                >
                  {title}
                </MenuItem>
              );
            })}
          </Menu>
        </li>

        {/* Trainer */}
        <li className="schedule-main-filter__item">
          <span
            className={`schedule-main-filter__title ${trainersMenu ? 'expanded' : ''}`}
            aria-controls={trainersMenu ? 'trainer-menu-filter' : undefined}
            aria-haspopup="true"
            onClick={(event) => {
              handleClosePreviousMenu();
              setTrainersMenu(event.currentTarget);
            }}
          >
            {activeTrainerFilter}
            <DropDownIcon />
          </span>

          <Menu
            id="trainer-menu-filter"
            classes={{ paper: 'dropdown-menu' }}
            anchorEl={trainersMenu}
            open={!!trainersMenu}
            onClose={handleClosePreviousMenu}
          >
            <MenuItem
              onClick={() => {
                // Если параметра этого фильтра нет, то не делать никаких запросов
                if (!filterParams.has('trainer__in')) {
                  handleClosePreviousMenu();
                  return;
                }

                setActiveTrainerFilter('тренер'); // Set default value
                deleteFilterHandler('trainer__in'); // Delete param
                handleClosePreviousMenu();
              }}
            >
              --- Все ---
            </MenuItem>

            {trainers.map(({ id, first_name, last_name }) => {
              return (
                <MenuItem
                  key={id}
                  className={`${trainerSelected?.includes(id) ? 'selected' : ''} `}
                  onClick={() => {
                    addFilterHandler('trainer__in', id); // Add filter param
                    // setActiveTrainerFilter(`${last_name} ${first_name}`); // Set selected name
                  }}
                >
                  {last_name} {first_name}
                </MenuItem>
              );
            })}
          </Menu>
        </li>

        {/* Level */}
        <li className="schedule-main-filter__item">
          <span
            className={`schedule-main-filter__title ${levelMenu ? 'expanded' : ''}`}
            aria-controls={levelMenu ? 'level-menu-filter' : undefined}
            aria-haspopup="true"
            onClick={(event) => {
              handleClosePreviousMenu();
              setLevelMenu(event.currentTarget);
            }}
          >
            {activeLevelFilter}
            <DropDownIcon />
          </span>

          <Menu
            id="level-menu-filter"
            classes={{ paper: 'dropdown-menu' }}
            anchorEl={levelMenu}
            open={!!levelMenu}
            onClose={handleClosePreviousMenu}
          >
            <MenuItem
              onClick={() => {
                // Если параметра этого фильтра нет, то не делать никаких запросов
                if (!filterParams.has('level__in')) {
                  handleClosePreviousMenu();
                  return;
                }

                setActiveLevelFilter('уровень'); // Set default value
                deleteFilterHandler('level__in'); // Delete param
                handleClosePreviousMenu();
              }}
            >
              --- Все ---
            </MenuItem>

            {level.map(({ id, title }) => {
              return (
                <MenuItem
                  key={id}
                  className={`${levelSelected?.includes(id) ? 'selected' : ''} `}
                  onClick={() => {
                    addFilterHandler('level__in', id); // Add filter param
                    // setActiveLevelFilter(title); // Set selected name
                  }}
                >
                  {title}
                </MenuItem>
              );
            })}
          </Menu>
        </li>

        {/* Gender */}
        {/* потом вернуть */}
        {/*<li className="schedule-main-filter__item">*/}
        {/*  <span*/}
        {/*    className={`schedule-main-filter__title ${genderMenu ? 'expanded' : ''}`}*/}
        {/*    aria-controls={genderMenu ? 'gender-menu-filter' : undefined}*/}
        {/*    aria-haspopup="true"*/}
        {/*    onClick={(event) => {*/}
        {/*      handleClosePreviousMenu();*/}
        {/*      setGenderMenu(event.currentTarget);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {activeGenderFilter}*/}
        {/*    <DropDownIcon />*/}
        {/*  </span>*/}

        {/*  <Menu*/}
        {/*    id="gender-menu-filter"*/}
        {/*    classes={{ paper: 'dropdown-menu' }}*/}
        {/*    anchorEl={genderMenu}*/}
        {/*    open={!!genderMenu}*/}
        {/*    onClose={handleClosePreviousMenu}*/}
        {/*  >*/}
        {/*    <MenuItem*/}
        {/*      onClick={() => {*/}
        {/*        // Если параметра этого фильтра нет, то не делать никаких запросов*/}
        {/*        if (!filterParams.has('gender')) {*/}
        {/*          handleClosePreviousMenu();*/}
        {/*          return;*/}
        {/*        }*/}

        {/*        setActiveGenderFilter('Гендер тренеровки'); // Set default value*/}
        {/*        deleteFilterHandler('gender'); // Delete param*/}
        {/*        handleClosePreviousMenu();*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      --- Все ---*/}
        {/*    </MenuItem>*/}

        {/*    {gender.map(({ id, title, name }) => {*/}
        {/*      return (*/}
        {/*        <MenuItem*/}
        {/*          key={id}*/}
        {/*          onClick={() => {*/}
        {/*            addFilterHandler('gender', id, title); // Add filter param*/}
        {/*            setActiveGenderFilter(name); // Set selected name*/}
        {/*            handleClosePreviousMenu();*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {name}*/}
        {/*        </MenuItem>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Menu>*/}
        {/*</li>*/}
      </ul>

      {/* Reset filter button */}
      {isResetFilterBtn && (
        <button className="reset-filter" onClick={resetFilter}>
          очистить фильтры
        </button>
      )}
    </>
  );
};

export default ScheduleFilter;
