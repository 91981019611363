import React from 'react';

// Files
import './AppPageBottomDecor.scss';
import img from './background-bottom.png';

// Components
import Image from '../../Image/Image';

const AppPageBottomDecor = () => {
  return (
    <div className="app-page-bottom-decor">
      <Image desk={img} alt={'page-bottom-decor'} title={'page-bottom-decor'} />
    </div>
  );
};

export default AppPageBottomDecor;
