import React, { useContext, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Rating, Alert } from '@mui/material';

// Files
import './CommentPopup.scss';
import { api } from '../../constants/api';
import { removeAuthStorage, toggleBodyScrollHandler } from '../../constants/constants';

// Components
import ClosePopup from '../IconComponents/ClosePopup';

// Context
import { AccountPopupContext, AddCommentPopupContext, IsLoggedInContext } from '../App/App';
import { SelectedClassContext } from '../../pages/AccountPage/TrainingHistoryPopup/TrainingHistoryPopup';

const CommentPopup = ({ previousComments, target = 's', targetId = null }) => {
  // Use context
  const { setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context
  // контекст состояния попапа комментария
  const { addCommentPopupOpen, setAddCommentPopupOpen, isCommentPopupForm, setIsCommentPopupForm } =
    useContext(AddCommentPopupContext);
  const { setSendCommentMessage } = useContext(SelectedClassContext);

  // контекст состояния попапа логина
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext);

  // State
  const [ratingValue, setRatingValue] = useState(0); // Rating state
  const [addCommentAlert, setAddCommentAlert] = useState(''); // Ошибка при отправлении коммента

  let targetTitle;

  if (target === 'g') {
    targetTitle = 'зала';
  } else if (target === 'k') {
    targetTitle = 'занятия';
  } else if (target === 't') {
    targetTitle = 'тренера';
  } else {
    targetTitle = 'школы';
  }

  /*** Handlers ***/
  // Close popup
  const closePopupHandler = () => {
    setAddCommentPopupOpen(false); // Hide form

    toggleBodyScrollHandler();

    // Reset inputted field and rating value after close popup
    setTimeout(() => {
      setIsCommentPopupForm(false);
      setRatingValue(0);
    }, 250);
  };

  const submitHandler = (content, resetForm) => {
    const body = {
      content: '',
      rating: 0,
      target: target,
      gym: null,
      klass: null,
      trainer: null,
    };

    let errorTargetObj = '';

    // Add comment and rating to body
    body.content = content;
    body.rating = ratingValue;

    // Comment for gym page
    if (target === 'g' && targetId) {
      body.gym = targetId;
      errorTargetObj = 'залу';
    }

    // Comment for class
    if (target === 'k' && targetId) {
      body.klass = targetId;
      errorTargetObj = 'занятию';
    }

    // Comment for trainer page
    if (target === 't' && targetId) {
      body.trainer = targetId;
      errorTargetObj = 'тренеру';
    }

    // Send request
    api
      .post('reviews/', body)
      .then(({ data }) => {
        if (previousComments) {
          previousComments.push(data);
        }

        // сообщение об отправке отзыва для занятия
        if (target === 'k' && targetId) {
          setSendCommentMessage('show-msg');

          setTimeout(() => setSendCommentMessage(''), 3000);
        }

        closePopupHandler();

        // очистить форму и рейтинг
        setTimeout(() => {
          resetForm();
          setRatingValue(0);
        }, 500);
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          // не выбрано значение рейтинга
          if (response.data.rating) {
            setAddCommentAlert('Выберите значение рейтинга');
          }

          // коммент уже оставлен
          if (response.data.non_field_errors) {
            setAddCommentAlert(`Вы уже оставляли комментарий к этому ${errorTargetObj}`);
          }

          setTimeout(() => setAddCommentAlert(''), 4000);
        }

        // недостаточное количество посещенных тренировок
        if (response.status === 403) {
          setAddCommentAlert(response.data.detail);

          setTimeout(() => setAddCommentAlert(''), 4000);
        }

        // Show login popup
        if (response.status === 401) {
          removeAuthStorage();

          setIsLoggedIn(false); // Logout user
          setIsPopupAccountOpen(true); // Show login popup
        }
      });
  };

  return (
    <div className={`popup-wrapper comment-form-popup-wrapper ${addCommentPopupOpen ? 'open' : ''}`}>
      <div className="popup comment-form-popup">
        <h3 className="popup__title">Школа</h3>

        {/* Add comment error notification */}
        {addCommentAlert && (
          <Alert className="add-comment-error" variant="outlined" severity="warning">
            {addCommentAlert}
          </Alert>
        )}

        <div className="popup__content">
          {isCommentPopupForm && (
            <Formik
              initialValues={{ rating: '', content: '' }}
              onSubmit={({ content }, { resetForm }) => submitHandler(content, resetForm)}
            >
              <Form className="form comment-form">
                <div className="form__body">
                  <div className="form__group">
                    <label className="form__label form__label--rating">
                      <span className="form__label-name">Оценка {targetTitle}</span>

                      <Rating
                        name="rating"
                        value={ratingValue}
                        onChange={(event, newValue) => setRatingValue(newValue)}
                      />
                    </label>
                  </div>

                  <div className="form__group">
                    <label className="form__label">
                      <span className="form__label-name">Комментарий</span>

                      <Field
                        name="content"
                        component="textarea"
                        className="form__textarea"
                        placeholder="Ваши впечатления"
                      />
                    </label>
                  </div>
                </div>

                <div className="form__footer">
                  <button type="submit" className="btn btn--bg form__submit">
                    Сохранить
                  </button>

                  <button type="button" className="btn form__btn" onClick={closePopupHandler}>
                    Отмена
                  </button>
                </div>
              </Form>
            </Formik>
          )}
        </div>

        <button className="popup__close" onClick={closePopupHandler}>
          <ClosePopup />
        </button>
      </div>
    </div>
  );
};

export default CommentPopup;
