import React from 'react';

// Files
import './InfoBlock.scss';

// Components
import Image from '../../../components/Image/Image';

const InfoBlock = ({ data }) => {
  // первый блок
  const blockOne =
    data?.first_block_title || data?.first_block_description || data?.first_block_image || data?.first_block_image_mob;
  // заголовок без последнего слова
  const blockOneTitle = data?.first_block_title?.substring(0, data?.first_block_title?.lastIndexOf(' '));
  // последнее слово заголовка
  const blockOneSubstringTitle = data?.first_block_title?.split(' ').pop();
  // картинки первого блока
  const blockOneImg = data?.first_block_image;
  const blockOneImgMob = data?.first_block_image_mob;
  // текст визивига первого блока
  const blockOneContentEdited = data?.first_block_description;

  // второй блок
  const blockSecond =
    data?.second_block_title ||
    data?.second_block_description ||
    data?.second_block_image ||
    data?.second_block_image_mob;
  // заголовок без последнего слова
  const blockSecondTitle = data?.second_block_title?.substring(0, data?.second_block_title?.lastIndexOf(' '));
  // последнее слово заголовка
  const blockSecondSubstringTitle = data?.second_block_title?.split(' ').pop();
  // картинки второго блока
  const blockSecondImg = data?.second_block_image;
  const blockSecondImgMob = data?.second_block_image_mob;
  // текст визивига второго блока
  const blockSecondContentEdited = data?.second_block_description;

  return (
    <div className="info-block">
      {blockOne && (
        <div className="info-block__item">
          <div className="info-block__image">
            <Image mob={blockOneImgMob} desk={blockOneImg} alt="info 1" />
          </div>

          <div className="info-block__content">
            <h2 className="info-block__content-title">
              {blockOneTitle} <span>{blockOneSubstringTitle}</span>
            </h2>

            <div className="info-block__content-text" dangerouslySetInnerHTML={{ __html: blockOneContentEdited }} />
          </div>
        </div>
      )}

      {blockSecond && (
        <div className="info-block__item info-block__item--reverse">
          <div className="info-block__image">
            <Image mob={blockSecondImgMob} desk={blockSecondImg} alt="info 2" />
          </div>

          <div className="info-block__content">
            <h2 className="info-block__content-title">
              {blockSecondTitle} <span>{blockSecondSubstringTitle}</span>
            </h2>

            <div className="info-block__content-text" dangerouslySetInnerHTML={{ __html: blockSecondContentEdited }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoBlock;
