import React from 'react';
import { Link } from 'react-router-dom';

// Files
import './DropdownMenu.scss';

// Components
import DropDownIcon from '../../IconComponents/DropDownIcon';

const DropdownMenu = ({ menu }) => {
  const linkUp = menu.filter((item) => item.position === 'u');
  const dropdownLink = linkUp.filter((item) => item.children.length !== 0); //массивы с children

  return (
    <div className="dropdown">
      <div className="dropdown__btn">
        {dropdownLink.length > 0 && dropdownLink[0].title}

        <span className="dropdown__menu-icon">
          <DropDownIcon />
        </span>

        {dropdownLink.map(({ slug, children }) => {
          const filteredChildren = children.filter(({ is_published }) => is_published === true);

          return (
            <div key={slug} className="dropdown__menu">
              {filteredChildren.map(({ id, slug, title }) => {
                return (
                  <Link className="dropdown__menu-item" to={slug} key={id}>
                    {title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownMenu;
