import React, { createContext, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Files
import { toggleBodyScrollHandler } from '../../../constants/constants';
import './TrainingHistoryPopup.scss';
import { formatTrainingHistory } from './index';

// Components
import CommentPopup from '../../../components/CommentPopup/CommentPopup';
import ClosePopup from '../../../components/IconComponents/ClosePopup';
import TrainingHistoryClass from '../TrainingHistoryClass/TrainingHistoryClass';

// Context
import { TrainingHistoryPopupContext } from '../AccountPage';
export const SelectedClassContext = createContext({}); // контекст выбранного id занятия для комментов

const TrainingHistoryPopup = () => {
  const { trainingHistoryPopup, setTrainingHistoryPopup, trainingHistoryList, sendTrainingHistoryRequest } =
    useContext(TrainingHistoryPopupContext);

  // Состояние новго сформированного объекта данных для истории тренировок
  const [trainingHistoryDataObj, setTrainingHistoryDataObj] = useState({});

  const [selectedClassId, setSelectedClassId] = useState(null); // Id занятия для написаиня коммента
  const [sendCommentMessage, setSendCommentMessage] = useState(''); // Sent class comment state

  // Формирование новой структуры данных для тренировок
  // происходит только при клике на кнопку истории тренировок. запрос произошел
  useEffect(() => {
    // Форматировать историю тренировок и закинуть в состояние
    setTrainingHistoryDataObj(formatTrainingHistory(trainingHistoryList));
  }, [sendTrainingHistoryRequest]);

  /*** Handlers ***/
  // Close popup
  const closePopupHandler = () => {
    setTrainingHistoryPopup(false); // Hide training history popup

    toggleBodyScrollHandler();
  };

  return (
    <SelectedClassContext.Provider value={{ setSelectedClassId, setSendCommentMessage }}>
      <div
        className={`popup-wrapper training-history-popup-wrapper ${sendCommentMessage} ${
          trainingHistoryPopup ? 'open' : ''
        }`}
      >
        <div className="popup training-history-popup">
          <h3 className="popup__title">история тренировок</h3>

          {sendCommentMessage && (
            <p className="training-history-notification">
              Отзыв оставлен. Посмотреть все отзывы можно на странице <Link to="/schedule/">расписания</Link>
            </p>
          )}

          <div className="popup__content training-history">
            {Object.keys(trainingHistoryDataObj).length === 0 && <p>Пройденных тренировок нет</p>}

            {Object.keys(trainingHistoryDataObj).length > 0 &&
              Object.entries(trainingHistoryDataObj).map(([monthName, monthTrainings]) => {
                return (
                  <div className="training-history__item" key={monthName}>
                    <div className="training-history__item-title">{monthName}</div>

                    <ul className="training-history-list">
                      {monthTrainings.map((item, index) => {
                        return (
                          <li className="training-history-list__item" key={index}>
                            <TrainingHistoryClass data={item} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>

          <button className="popup__close" onClick={closePopupHandler}>
            <ClosePopup />
          </button>
        </div>
      </div>

      {/*** Comment popup ***/}
      <CommentPopup target={'k'} targetId={selectedClassId} />
    </SelectedClassContext.Provider>
  );
};

export default TrainingHistoryPopup;
