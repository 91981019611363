/*** Определение времени начала и конца занятия ***/
export const convertClassTime = (date, duration) => {
  const classEndTimeMs = new Date(date).getTime() + duration * 60 * 1000; // Найти окончание тренировки в мс
  const classEndTimeStr = new Date(classEndTimeMs); // Окончание тренировки в виде строки
  const classEndTimeHours = classEndTimeStr.getHours().toString().padStart(2, '0'); // Окончание тренировки (ч)
  const classEndTimeMinutes = classEndTimeStr.getMinutes().toString().padStart(2, '0'); // Окончание тренировки (м)
  const classStartTime = date.split(/[T+]/)[1].slice(0, -3); // Найти подстроку со временем в date (часы и минуты)
  const classEndTime = `${classEndTimeHours}:${classEndTimeMinutes}`; // Время окончания тренировки

  return {
    startTime: classStartTime,
    endTime: classEndTime,
  };
};
