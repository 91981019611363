/*** Get freeze end date ***/
export const getFreezeEndDate = (durationSeconds) => {
  const date = Date.now() / 1000; // current date seconds
  const endDate = new Date((date + durationSeconds) * 1000); // сложить секунды и перевести в миллисек

  const endDay = endDate.getDate().toString().padStart(2, '0'); // day
  const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0'); // month
  const endYear = endDate.getFullYear(); // year

  return `${endDay}.${endMonth}.${endYear}`;
};

/*** Update user abonement balance ***/
export const updBalance = (currentBalance, abonemetnId) => {
  return currentBalance.map((item) => {
    if (item.id === abonemetnId) {
      item.freeze = !item.freeze;
    }

    return item;
  });
};
