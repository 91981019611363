import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { descriptionDivision } from '../../constants/constants';

// Files
import { api } from '../../constants/api';
import { formatDate, removeAuthStorage, toggleBodyScrollHandler } from '../../constants/constants';
import './AccountPage-grid.scss';
import './AccountPage.scss';

// Components
import BallDecor from '../../components/IconComponents/BallDecor';
import Image from '../../components/Image/Image';
import ChangePasswordPopUp from './ChangePasswordPopup/ChangePasswordPopUp';
import EditProfilePopup from './EditProfilePopup/EditProfilePopup';
import FreezeAbonementPopup from './FreezeAbonementPopup/FreezeAbonementPopup';
import TrainingHistoryPopup from './TrainingHistoryPopup/TrainingHistoryPopup';

// Context
import { AccountPopupContext, IsLoggedInContext } from '../../components/App/App';

export const EditProfilePopupContext = createContext({}); // Edit profile popup state context
export const TrainingHistoryPopupContext = createContext({}); // Training history popup context
export const FreezeAbonementPopupContext = createContext({}); // Freeze abonement popup context

const AccountPage = () => {
  const navigate = useNavigate();

  // Use context
  const { setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext); // Use app account popup context

  const mobScreen = window.matchMedia('(max-width: 743px)'); // Mobile media query
  const [isMobScreen, setIsMobScreen] = useState(mobScreen.matches); // State for mobile screen

  const [userAccountData, setUserAccountData] = useState({}); // User account data
  const [userAccountBalance, setUserAccountBalance] = useState([]); // User account balance
  const [selectedTab, setSelectedTab] = useState('about'); // Selected tab in user info
  const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false); // Edit popup state
  const [isEditProfileForm, setIsEditProfileForm] = useState(true); // Edit form state in popup
  const [isChangePasswordPopUpOpen, setIsChangePasswordPopUpOpen] = useState(false);
  // Edit password popup state

  const [trainingHistoryPopup, setTrainingHistoryPopup] = useState(false); // Training history popup state
  const [trainingHistoryList, setTrainingHistoryList] = useState([]); // Training history list
  const [sendTrainingHistoryRequest, setSendTrainingHistoryRequest] = useState(false); // History request state

  const [isFrozenAbonementPopup, setIsFrozenAbonementPopup] = useState(false); // Freeze abonement popup state
  const [isFrozenAbonement, setIsFrozenAbonement] = useState(false); // Freeze abonement state
  const [freezeAbonementId, setFreezeAbonementId] = useState(null); // Freeze abonement id state
  const [unFreezeEndDate, setUnFreezeEndDate] = useState(''); // End freeze date for unfreeze popup

  const [paragraphsAbout, setParagraphsAbout] = useState(null);
  const [readMore, setReadMore] = useState(false);

  let countSymbols = 22;

  useEffect(() => {
    // Send request for user account data
    api
      .get('profile/')
      .then(({ data }) => {
        console.log(data);
        setUserAccountBalance(data.balance); // данные абонементов
        setUserAccountData(data); // данные пользователя
        setParagraphsAbout(descriptionDivision(data.about, countSymbols));
      })
      .catch((error) => {
        // Logout user and redirect to main page and show login popup
        if (error.response.status === 401) {
          removeAuthStorage();

          setIsLoggedIn(false); // Logout user
          navigate('/'); // Redirect to main page
          setIsPopupAccountOpen(true); // Show login popup
        }
      });

    // Check screen width for mobile
    const handleScreenChange = (event) => setIsMobScreen(event.matches);
    mobScreen.addEventListener('change', handleScreenChange);
    return () => mobScreen.removeEventListener('change', handleScreenChange);
  }, []);

  const isUserAccountData = Object.keys(userAccountData).length !== 0; // Check if user account data
  const {
    about,
    achievements,
    avatar,
    birthday,
    email,
    experience,
    first_name,
    height,
    last_name,
    level_id,
    near_klass,
    phone,
    role,
    telegram,
    username,
  } = userAccountData;

  // Switch user info tabs
  const activeTabContentClass = selectedTab === 'about' ? 'about' : 'contacts';
  const selectedTabNavHandler = (tab) => (selectedTab === tab ? 'selected' : '');

  /*** Handlers ***/
  const trainingsHistoryHandler = () => {
    setTrainingHistoryPopup(true); // Show training history popup

    api
      .get('history/')
      .then(({ data }) => {
        setTrainingHistoryList(data);
        // обозначить, что запрос был сделан. для изменения структуры данных, только если запрос был сделан
        setSendTrainingHistoryRequest(true);
      })
      .catch((error) => {
        // Logout user and redirect to main page and show login popup
        if (error.response.status === 401) {
          removeAuthStorage();

          setIsLoggedIn(false); // Logout user
          navigate('/'); // Redirect to main page
          setIsPopupAccountOpen(true); // Show login popup
        }
      });

    toggleBodyScrollHandler();
  };

  const handleFreezePopup = (abonementId, freezeState) => {
    // если заморожен, получить конечную дату заморозки
    if (freezeState) {
      api.get(`freeze_date_end/${abonementId}/`).then(({ data }) => {
        const date = data.freeze_end.split('T')[0].split('-').reverse().join('.');

        setUnFreezeEndDate(date);
      });
    }

    setFreezeAbonementId(abonementId); // id активного абонемента
    setIsFrozenAbonement(freezeState); // состояние абонемента
    setIsFrozenAbonementPopup(true); // состояние попапа
  };

  const handleChangePasswordPopUp = () => {
    setIsChangePasswordPopUpOpen(true);
  };

  return (
    <EditProfilePopupContext.Provider
      value={{
        avatar,
        setUserAccountData,
        isEditProfilePopupOpen,
        setIsEditProfilePopupOpen,
        isEditProfileForm,
        setIsEditProfileForm,
      }}
    >
      <TrainingHistoryPopupContext.Provider
        value={{ trainingHistoryPopup, setTrainingHistoryPopup, trainingHistoryList, sendTrainingHistoryRequest }}
      >
        <FreezeAbonementPopupContext.Provider
          value={{
            unFreezeEndDate,
            userAccountBalance,
            setUserAccountBalance,
            freezeAbonementId,
            isFrozenAbonementPopup,
            setIsFrozenAbonementPopup,
            isFrozenAbonement,
          }}
        >
          <div className="container">
            <h1 className="page-title page-title--account">Личный кабинет</h1>

            {isUserAccountData && (
              <>
                <div className="account">
                  {/*** Edit profile button for table and desktop ***/}
                  {!isMobScreen && (
                    <div className="btn-edit-profile-wrapper">
                      <button
                        className="btn btn--edit-profile"
                        onClick={() => {
                          setIsEditProfileForm(true);
                          setIsEditProfilePopupOpen(true);
                          toggleBodyScrollHandler();
                        }}
                      >
                        редактировать профиль
                      </button>
                    </div>
                  )}

                  {/*** Account info ***/}
                  <section className="account__info">
                    <div className="user-preview">
                      <div className="user-preview__image account-block-border">
                        {avatar ? <Image desk={avatar} alt={avatar} title={avatar} /> : <BallDecor />}
                      </div>

                      {/* Временно закомментировано. вернуть позже */}
                      {/*<div className="user-preview__role account-block-border account-block-border--mob">*/}
                      {/*  <h3>Амплуа</h3>*/}

                      {/*  {role.length > 0*/}
                      {/*    ? role.map(({ id, name }, index) => {*/}
                      {/*        const isComma = role.length - 1 !== index ? ', ' : '';*/}

                      {/*        return (*/}
                      {/*          <span key={id}>*/}
                      {/*            {name}*/}
                      {/*            {isComma}*/}
                      {/*          </span>*/}
                      {/*        );*/}
                      {/*      })*/}
                      {/*    : 'Роль не выбрана'}*/}
                      {/*</div>*/}
                    </div>

                    {/*** Edit profile button for mobile ***/}
                    {isMobScreen && (
                      <>
                        <button
                          className="btn btn--edit-profile"
                          onClick={() => {
                            setIsEditProfileForm(true);
                            setIsEditProfilePopupOpen(true);
                            toggleBodyScrollHandler();
                          }}
                        >
                          редактировать профиль
                        </button>
                      </>
                    )}

                    {/* User info */}
                    <div className="user-info">
                      <div className={`user-info__tabs-nav ${activeTabContentClass}-nav`}>
                        <span
                          className={`tabs-title tabs-title--about ${selectedTabNavHandler('about')}`}
                          onClick={() => setSelectedTab('about')}
                        >
                          о себе
                        </span>
                        <span
                          className={`tabs-title tabs-title--contacts ${selectedTabNavHandler('contacts')}`}
                          onClick={() => setSelectedTab('contacts')}
                        >
                          контакты
                        </span>
                      </div>

                      <div className={`user-info__tabs-content ${activeTabContentClass} account-block-border`}>
                        <div className="user-info__content">
                          {selectedTab === 'about' ? (
                            <>
                              <div className="user-info__content-item user-info__item--email">
                                <span className="account-description-title">Логин</span>
                                <span className="account-description-text">{username}</span>
                              </div>

                              <div className="user-info__content-item user-info__content-item--birthday">
                                <span className="account-description-title">Дата рождения</span>
                                <span className="account-description-text">{formatDate(birthday)}</span>
                              </div>

                              <div className="user-info__content-item user-info__content-item--height">
                                <span className="account-description-title">Рост</span>
                                <span className="account-description-text">
                                  {height ? `${height} см` : 'не указан'}
                                </span>
                              </div>

                              <div className="user-info__content-item user-info__content-item--password">
                                <span className="account-description-title">Пароль</span>
                                <button onClick={handleChangePasswordPopUp} className="btn account-change-password-btn">
                                  Изменить
                                </button>
                              </div>

                              {/* <div className="user-info__content-item user-info__item--description">
                                <div className="shape-float" />
                                <div className="text-float">
                                  <span className="account-description-title">О себе</span>
                                  <div className="user-info__content-item--description-container">
                                    {paragraphsAbout
                                      ? paragraphsAbout.map((item, index) => (
                                          <p
                                            key={index}
                                            className={
                                              (readMore && index > 0) || index == 0
                                                ? 'user-info__content-item--description-visible'
                                                : 'user-info__content-item--description-hide'
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: item.replaceAll('\n', '<br /><br />'),
                                            }}
                                          ></p>
                                        ))
                                      : 'Описания нет'}
                                  </div>
                                  <div
                                    className={`user-info__content-item--description readMore-container ${
                                      readMore ? 'expand' : ''
                                    }`}
                                  >
                                    {paragraphsAbout && paragraphsAbout[1] !== '' ? (
                                      <button
                                        type="button"
                                        className="user-info__content-item--description-readMore"
                                        onClick={() => setReadMore(!readMore)}
                                      >
                                        {!readMore ? 'Читать далее' : 'Свернуть'}
                                      </button>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                </div>
                              </div> */}
                            </>
                          ) : (
                            <>
                              <div className="user-info__content-item user-info__content-item--password">
                                <span className="account-description-title">Имя и фамилия</span>
                                <span className="account-description-text">
                                  {last_name || first_name ? `${last_name} ${first_name}` : 'Не указано'}
                                </span>
                              </div>

                              <div className="user-info__content-item user-info__content-item--password">
                                <span className="account-description-title">Телефон</span>
                                <span className="account-description-text">{phone ? phone : 'Не указан'}</span>
                              </div>

                              <div className="user-info__content-item user-info__content-item--password">
                                <span className="account-description-title">Почта</span>
                                <span className="account-description-text">{email ? email : 'Не указана'}</span>
                              </div>

                              <div className="user-info__content-item user-info__content-item--password">
                                <span className="account-description-title">Telegram</span>
                                <span className="account-description-text">
                                  {telegram ? telegram.replace('https://t.me/', '@') : 'Не указан'}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Trainings & abonements for desktop */}
                    {!isMobScreen && (
                      <div className="trainings account-block-border">
                        <div className="trainings__item-wrapper">
                          <h3>Тренировки</h3>

                          {/* Ближайшая тренировка */}
                          <div className="trainings__item trainings__item--nearest">
                            <span className="account-description-title">Ближайшая</span>
                            <p className="account-description-text">
                              {near_klass?.date ? formatDate(near_klass.date) : 'Ближайших нет'}
                            </p>
                          </div>

                          {/* Таблица абонементов */}
                          <div className="trainings__item trainings__item--rest">
                            <div className="trainings__heading account-description-title">
                              <div className="trainings__heading-type">Вид абонемента</div>
                              <div className="trainings__heading-left">Остаток</div>
                              <div className="trainings__heading-expiry">Срок действия</div>
                              {/* <div className="trainings__heading-empty" /> */}
                            </div>

                            <div className="trainings__body account-description-title">
                              {userAccountBalance.length === 0 && (
                                <p className="account-description-text">Абонементов нет</p>
                              )}

                              {userAccountBalance.map(({ id, product, balance, freeze, date_end }) => {
                                let expiryDate;
                                if (date_end) {
                                  expiryDate = date_end.split('T')[0].split('-').reverse().join('.');
                                }

                                return (
                                  <div className="trainings__body-item account-description-text" key={id}>
                                    <div className={`trainings__heading-type${freeze ? ' freezed' : ''}`}>
                                      {product}
                                    </div>
                                    <div className={`trainings__heading-left${freeze ? ' freezed' : ''}`}>
                                      {balance}
                                    </div>
                                    <div className={`trainings__heading-expiry${freeze ? ' freezed' : ''}`}>
                                      {date_end ? `до ${expiryDate}` : 'не активирован'}
                                    </div>
                                    {date_end && (
                                      <div className="trainings__heading-empty">
                                        <button
                                          className="btn btn--freeze-abonement"
                                          onClick={() => handleFreezePopup(id, freeze)}
                                        >
                                          {!freeze ? 'заморозить' : 'разморозить'}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {/* Истории тренировок */}
                          <button className="btn btn--bg trainings__history" onClick={trainingsHistoryHandler}>
                            история тренировок
                          </button>
                        </div>

                        <span className="trainings__bottom-decor" />
                      </div>
                    )}
                  </section>

                  {/*** Account level ***/}
                  <section className="account__level">
                    <div className="user-level account-block-border">
                      <h3>уровень</h3>

                      <div className="user-level__content">
                        <div className="user-level__content-image">
                          <Image desk={level_id.image} alt={level_id.title} title={level_id.title} />
                        </div>

                        <span className="user-level__content-title">{level_id.title}</span>

                        <div className="user-level__content-text">
                          <span className="account-description-title">Опыт</span>
                          <span className="account-description-text">
                            {experience ? `${experience} лет` : 'не указан'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/*** Trainings & abonements for mobile ***/}
                  {isMobScreen && (
                    <div className="trainings account-block-border">
                      <div className="trainings__item-wrapper">
                        <h3>Тренировки</h3>

                        {/* Ближайшая тренировка */}
                        <div className="trainings__item trainings__item--nearest">
                          <span className="account-description-title">Ближайшая</span>
                          <p className="account-description-text">
                            {near_klass?.date ? formatDate(near_klass.date) : 'Ближайших нет'}
                          </p>
                        </div>

                        {/* Таблица абонементов */}
                        <div className="trainings__item trainings__item--rest">
                          <div className="trainings__heading account-description-title">
                            <div className="trainings__heading-type">Вид абонемента</div>
                            <div className="trainings__heading-left">Остаток</div>
                            <div className="trainings__heading-expiry">Срок действия</div>
                            <div className="trainings__heading-empty" />
                          </div>

                          <div className="trainings__body account-description-title">
                            {userAccountBalance.length === 0 && (
                              <p className="account-description-text">Абонементов нет</p>
                            )}

                            {userAccountBalance.map(({ id, product, balance, freeze, date_end }) => {
                              let expiryDate;
                              if (date_end) {
                                expiryDate = date_end.split('T')[0].split('-').reverse().join('.');
                              }

                              return (
                                <div className="trainings__body-item account-description-text" key={id}>
                                  <div className={`trainings__heading-type${freeze ? ' freezed' : ''}`}>{product}</div>
                                  <div className={`trainings__heading-left${freeze ? ' freezed' : ''}`}>{balance}</div>
                                  <div className={`trainings__heading-expiry${freeze ? ' freezed' : ''}`}>
                                    {date_end ? `до ${expiryDate}` : 'не активирован'}
                                  </div>
                                  {date_end && (
                                    <div className="trainings__heading-empty">
                                      <button
                                        className="btn btn--freeze-abonement"
                                        onClick={() => handleFreezePopup(id, freeze)}
                                      >
                                        {!freeze ? 'заморозить' : 'разморозить'}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* Истории тренировок */}
                        <button className="btn btn--bg trainings__history" onClick={trainingsHistoryHandler}>
                          история тренировок
                        </button>
                      </div>

                      <span className="trainings__bottom-decor" />
                    </div>
                  )}

                  {/*** Account informers ***/}
                  <section className="account__informers hidden">
                    {/* Achievements */}
                    <div className="achievements account-block-border">
                      <h3>Достижения</h3>

                      {achievements.length === 0 ? (
                        <p className="account-no-info">-</p>
                      ) : (
                        <div className="achievements__images-wrapper">
                          {achievements.map(({ id, is_published, logo, title }) => {
                            if (is_published) {
                              return (
                                <div className="achievements__image" key={id}>
                                  <Image desk={logo} alt={title} title={title} />
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}

                      {/*<span className="achievements__bottom-decor" />*/}
                    </div>
                  </section>
                </div>

                {/*** History trainings popup ***/}
                <TrainingHistoryPopup />

                {/* Change password popup */}
                <ChangePasswordPopUp
                  isChangePasswordPopUpOpen={isChangePasswordPopUpOpen}
                  setIsChangePasswordPopUpOpen={setIsChangePasswordPopUpOpen}
                />

                {/*** Edit profile popup ***/}
                <EditProfilePopup userAccountData={userAccountData} />

                {/*** Freeze abonement popup ***/}
                <FreezeAbonementPopup />
              </>
            )}
          </div>
        </FreezeAbonementPopupContext.Provider>
      </TrainingHistoryPopupContext.Provider>
    </EditProfilePopupContext.Provider>
  );
};

export default AccountPage;
