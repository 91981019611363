import React, { useContext, useEffect, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Files
import './PaymentPage.scss';
import { api, apiHostName } from '../../constants/api';
import { priceConvertHandler, shuffle, removeAuthStorage } from '../../constants/constants';
import { productPreviewSliderOptions } from './sliderOptions';

// Components
import ProductPreviewCard from '../../components/ProductPreviewCard/ProductPreviewCard';
import AngleDecorSingle from '../../components/IconComponents/AngleDecorSingle';
import AngleDecorDouble from '../../components/IconComponents/AngleDecorDouble';
import Image from '../../components/Image/Image';

// Context
import { AccountPopupContext, IsLoggedInContext } from '../../components/App/App';
import { toast } from 'sonner';

const PaymentPage = () => {
  // Use context
  const { isLoggedIn, setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext);

  // User visit trial state
  const [canTrialVisit, setCanTrialVisit] = useState(null);

  // Type trainings state (categories)
  const [categoriesList, setCategoriesList] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(null);

  // Training package state (products)
  const [productsList, setProductsList] = useState([]);
  const [activeProductId, setActiveProductId] = useState(null);
  const [activeProductInfo, setActiveProductInfo] = useState([]);

  // Related packages state (related products). Should be filtered after change product id
  const [relatedProducts, setRelatedProducts] = useState([]);
  // Get all type categories and products on page load
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      api.get('has-trial-training/').then(({ data }) => setCanTrialVisit(data.has_trial_training));
    }

    api.get('type-training/short/').then(({ data }) => {
      const filteredCategoriesList = data.filter(
        ({ is_published, products }) => is_published === true && products.length > 0
      );
      setCategoriesList(filteredCategoriesList); // Set all categories data
      setActiveCategoryId(filteredCategoriesList[0].id); // Set the first category active
    });

    api.get('product/').then(({ data }) => setRelatedProducts(shuffle(data)));

    window.scrollTo({ top: 0 });
  }, [isLoggedIn]);
  // Change category
  useEffect(() => {
    const filteredCategoryArr = categoriesList.filter(({ id, products }) => activeCategoryId === id);
    const productsInFilteredCategoryArr = filteredCategoryArr[0]?.products;
    if (isLoggedIn) {
      api.get('has-trial-training/').then(({ data }) => {
        if (!data.has_trial_training) {
          const withoutTrial = productsInFilteredCategoryArr?.filter(({ slug }) => slug !== 'trial');

          // Set products of active category
          setProductsList(withoutTrial);

          // Set the first product active
          productsInFilteredCategoryArr && setActiveProductId(withoutTrial[0]?.id);
        } else {
          // Set products of active category
          setProductsList(productsInFilteredCategoryArr);

          // Set the first product active
          productsInFilteredCategoryArr && setActiveProductId(productsInFilteredCategoryArr[0]?.id);
        }
      });
    } else {
      // Set products of active category
      setProductsList(productsInFilteredCategoryArr);

      // Set the first product active
      productsInFilteredCategoryArr && setActiveProductId(productsInFilteredCategoryArr[0]?.id);
    }
  }, [activeCategoryId, categoriesList, isLoggedIn]);

  // Change product
  useEffect(() => {
    if (activeProductId) {
      api.get(`product/${activeProductId}/`).then(({ data }) => setActiveProductInfo(data));
    }
  }, [activeProductId]);

  return (
    <div className="container">
      <h1 className="page-title">цены</h1>

      {/*** Select categories and products ***/}
      <section className="select-training-wrapper select-product-wrapper">
        {/* Top left decor element */}
        <div className="select-training-wrapper__left-decor">
          <AngleDecorSingle />
        </div>

        {/* Content */}
        <div className="control select-product">
          <div className="control__block select-product__categories">
            {categoriesList.map(({ id, title }) => {
              return (
                <button
                  className={`control__item ${activeCategoryId === id ? 'active' : ''}`}
                  key={id}
                  onClick={() => setActiveCategoryId(id)}
                >
                  {title}
                </button>
              );
            })}
          </div>

          <div className="control__block select-product__products">
            {productsList &&
              productsList.map(({ id, is_published, slug, title }) => {
                const hideTrial = slug === 'trial' && !canTrialVisit && localStorage.getItem('access_token');

                return (
                  <button
                    className={`control__item ${hideTrial ? 'hidden' : ''} ${activeProductId === id ? 'active' : ''}`}
                    key={id}
                    onClick={() => setActiveProductId(id)}
                  >
                    {title}
                  </button>
                );
              })}
          </div>
        </div>

        {/* Bottom right decor element */}
        <div className="select-training-wrapper__right-decor">
          <AngleDecorDouble />
        </div>
      </section>

      {/*** Product info ***/}
      <section className="product-info-section product">
        <div className="product__image">
          <Image desk={activeProductInfo.image} alt={activeProductInfo.title} />
        </div>

        <div className="product__info">
          <p className="details-title product__title">{activeProductInfo.title}</p>

          <div className="product__description">
            <span className="product__description-title">Описание</span>

            <div
              className="product__description-list"
              dangerouslySetInnerHTML={{ __html: activeProductInfo.description }}
            />
          </div>

          {activeProductInfo.count_class > 1 ? (
            // больше 1 тренировки
            <div className="product__price">
              <div className="product__price-block">
                <p className="product__price-title">Цена за 1 тренировку</p>

                {/* стоимость за 1 тренировку */}
                <div className="price">
                  <div className="price__block price__block--current">
                    <span className="price__title">с абонементом</span>
                    <span className="price__current">{+activeProductInfo.price / activeProductInfo.count_class} P</span>
                  </div>

                  {activeProductInfo.old_price && (
                    <div className="price__block price__block--old">
                      <span className="price__title">без абонемента</span>
                      <div className="price__old">{+activeProductInfo.old_price / activeProductInfo.count_class} P</div>
                    </div>
                  )}
                </div>
              </div>

              {/* общая стоимость */}
              <div className="product__price-block">
                <p className="product__price-title">Общая стоимость абонемента</p>

                <span className="product__price-total">{priceConvertHandler(activeProductInfo.price)} P</span>
              </div>
            </div>
          ) : (
            // 1   тренировка
            <div className="product__price price">
              <span className="price__current">{priceConvertHandler(activeProductInfo.price)} P</span>
              {activeProductInfo.old_price && (
                <span className="price__old">{priceConvertHandler(activeProductInfo.old_price)} P</span>
              )}
            </div>
          )}

          <button
            className="btn btn--bg product__buy"
            onClick={() => {
              if (!localStorage.getItem('access_token')) {
                setIsPopupAccountOpen(true);
              } else {
                api
                  .get(`pay/${activeProductInfo.id}/`)
                  .then(({ data }) => (window.location = data.url))
                  .catch(({ response }) => {
                    // Logout user and show login popup
                    if (response.data.detail) {
                      toast.error(response.data.detail);
                    } else {
                      toast.error('Произошла ошибка, попробуйте снова');
                    }
                    if (response.status === 401) {
                      removeAuthStorage();

                      setIsLoggedIn(false); // Logout user
                      setIsPopupAccountOpen(true); // Show login popup
                    }
                  });
              }
            }}
          >
            купить
          </button>
        </div>
      </section>

      {/*** Related products ***/}
      {/* временно скрыто */}
      {/*<section className="related-products-section">*/}
      {/*  <h2 className="page-title">также покупают</h2>*/}

      {/*  <div className="related-products">*/}
      {/*    <Swiper {...productPreviewSliderOptions} modules={[Pagination]} className="related-products__slider">*/}
      {/*      {relatedProducts.map((item) => {*/}
      {/*        return (*/}
      {/*          <SwiperSlide key={item.id}>*/}
      {/*            <ProductPreviewCard previewItem={item} />*/}
      {/*          </SwiperSlide>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </Swiper>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </div>
  );
};

export default PaymentPage;
