import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Rating } from '@mui/material';

// Files
import './ScheduleClassReviews.scss';
import { toggleBodyScrollHandler } from '../../../constants/constants';

// Components
import ClosePopup from '../../../components/IconComponents/ClosePopup';
import Review from '../../../components/Review/Review';
import Image from '../../../components/Image/Image';

// Context
import { ClassReviewsContext } from '../SchedulePage';

const ScheduleClassReviews = () => {
  const { classReviewsPopupOpen, setClassReviewsPopupOpen, classReviews, classDataPopup } =
    useContext(ClassReviewsContext);

  const { id, date, duration, level, limit, playersCount, trainer, type } = classDataPopup;

  let classRatingCounter = 0;

  const [totalClassRating, setTotalClassRating] = useState(0); // общая оценка занятия

  // формирование даты этого занятия для отзывов занятия
  const newDate = new Date(date);
  const trainingMonth = newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
  const trainingDate = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
  let classStartTime;

  if (date) {
    classStartTime = date.split(/[T+]/)[1].slice(0, -3); // Найти подстроку со временем в date (часы и минуты)
  }

  const classEndTimeMs = new Date(date).getTime() + duration * 60 * 1000; // Найти окончание тренировки в мс
  const classEndTimeStr = new Date(classEndTimeMs); // Окончание тренировки в виде строки
  // Окончание тренировки (ч)
  const classEndTimeHours =
    classEndTimeStr.getHours() < 10 ? `0${classEndTimeStr.getHours()}` : classEndTimeStr.getHours();
  // Окончание тренировки (м)
  const classEndTimeMinutes =
    classEndTimeStr.getMinutes() < 10 ? `0${classEndTimeStr.getMinutes()}` : classEndTimeStr.getMinutes();

  // для каждого занятия высчитывается средняя оценка
  useEffect(() => {
    if (classReviews.length > 0) {
      classReviews.forEach(({ rating }) => {
        classRatingCounter = classRatingCounter + rating;
      });

      setTotalClassRating(classRatingCounter / classReviews.length);

      return;
    }

    setTotalClassRating(0);
  }, [id]);

  /*** Handlers ***/
  // Close popup
  const closePopupHandler = () => {
    setClassReviewsPopupOpen(false); // Hide reviews popup

    toggleBodyScrollHandler();
  };

  return (
    <div className={`popup-wrapper class-reviews-popup-wrapper ${classReviewsPopupOpen ? 'open' : ''}`}>
      <div className="popup class-reviews-popup">
        <div className="popup__content class-reviews" data-class-id={id}>
          {/*** Class info ***/}
          <div className="class-reviews__block class-info">
            <div className="class-info__header">
              <span className="class-info__title">
                Тренировка {trainingDate}.{trainingMonth}
              </span>

              <div className="class-info__rating">
                <span className="class-info__rating-title">Оценка тренировки</span>
                <Rating name="read-only" value={totalClassRating} readOnly />
              </div>
            </div>

            <span className="class-info__trainer-name">{`${trainer?.first_name} ${trainer?.last_name}`}</span>

            <div className="class-info__body">
              <div className="class-info__trainer-image">
                <Image desk={trainer?.avatar} alt={'training_image'} title={'training_image'} />
              </div>

              <div className="class-info__group">
                <div className="class-info__group-level">
                  <div className="class-info__group-level-image" style={{ backgroundColor: level?.color }} />

                  <span>{level?.title} уровень группы</span>
                </div>

                <div className="class-info__group-time">
                  {classStartTime} - {classEndTimeHours}:{classEndTimeMinutes}
                </div>

                <div className="class-info__group-type">{type}</div>
              </div>

              <div className="class-info__players">
                <p>Количество игороков</p>
                <p>
                  {playersCount}/{limit}
                </p>
              </div>
            </div>
          </div>

          {/*** Class reviews ***/}
          <div className="class-reviews__block">
            <h2 className="page-title">
              <span>Отзывы</span>
              <p className="class-reviews__add-review-notification">
                оставить отзыв об этой тренировке можно в <Link to="/account/">личном кабинете</Link> в истории
                тренировок
              </p>
            </h2>

            {classReviews.length > 0 ? (
              <ul className="reviews">
                {classReviews.map((item) => {
                  return (
                    <li key={item.id} className="reviews__item">
                      <Review item={item} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="no-reviews">Отзывов об этой тренировке нет</p>
            )}
          </div>
        </div>

        <button className="popup__close" onClick={closePopupHandler}>
          <ClosePopup />
        </button>
      </div>
    </div>
  );
};

export default ScheduleClassReviews;
