import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Pagination, Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// Files
import './TrainingPage.scss';
import { api } from '../../constants/api';
import { handleChangeTrainingType } from './index';

// Components
import AngleDecorDouble from '../../components/IconComponents/AngleDecorDouble';
import AngleDecorSingle from '../../components/IconComponents/AngleDecorSingle';
import Image from '../../components/Image/Image';

const TrainingPage = () => {
  const selectTrainingBlk = useRef(null);
  const [trainingPreviewHeight, setTrainingPreviewHeight] = useState(0); // Set height for training preview

  const mobScreen = window.matchMedia('(max-width: 743px)'); // Mobile media query
  const descScreen = window.matchMedia('(min-width: 992px)'); // Desktop media query

  const [isMobScreen, setIsMobScreen] = useState(mobScreen.matches); // State for mobile screen
  const [isDescScreen, setIsDescScreen] = useState(descScreen.matches); // State for desktop screen

  const [trainingList, setTrainingList] = useState([]);
  const [controlledTrainingInfo, setControlledTrainingInfo] = useState(null);
  const [controlledTrainingPagination, setControlledTrainingPagination] = useState(null);
  const [previewImgPath, setPreviewImgPath] = useState(null); // путь к картинке активного слайда

  useEffect(() => {
    api.get('type-training/').then(({ data }) => {
      const filteredTrainingList = data.filter(({ is_published }) => is_published === true);

      setTrainingList(filteredTrainingList); // список тренировок
      setPreviewImgPath(filteredTrainingList[0].image_main); // картинка превью первой тренировки
    });

    const handleScreenChange = (event) => {
      setIsMobScreen(event.matches);
      setIsDescScreen(event.matches);
    };

    mobScreen.addEventListener('change', handleScreenChange);
    descScreen.addEventListener('change', handleScreenChange);

    return () => {
      mobScreen.removeEventListener('change', handleScreenChange);
      descScreen.removeEventListener('change', handleScreenChange);
    };
  }, []);

  // Менять высоту картинки превью после получения инфы от тренировках и при ресайзе
  useEffect(() => {
    setTrainingPreviewHeight(selectTrainingBlk.current.clientHeight);
  }, [isMobScreen, isDescScreen, trainingList]);

  return (
    <>
      <div className="container">
        <h1 className="page-title">Виды тренировок</h1>

        {/*** Select training ***/}
        <section className="select-training-wrapper">
          {/* Top left decor element */}
          <div className="select-training-wrapper__left-decor">
            <AngleDecorSingle />
          </div>

          <div className="select-training">
            {/* Slider pagination buttons */}
            <Swiper
              className="select-training__list"
              ref={selectTrainingBlk}
              slidesPerView={1}
              modules={[Pagination, Controller]}
              pagination={{ clickable: true }}
              onSlideChange={() => {}}
              onSlideChangeTransitionStart={({ slides }) => setPreviewImgPath(handleChangeTrainingType(slides))}
              onSwiper={setControlledTrainingPagination}
              controller={{ control: controlledTrainingInfo }}
            >
              {trainingList.map(({ id, title, image_main }) => {
                return (
                  <SwiperSlide key={id} className="select-training__list-item" data-img-preview={image_main}>
                    <p>{title}</p>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {/* Image preview */}
            <div className="select-training__preview" style={{ height: trainingPreviewHeight }}>
              {previewImgPath && <Image desk={previewImgPath} alt={'preview-image-training'} />}
            </div>
          </div>

          {/* Bottom right decor element */}
          <div className="select-training-wrapper__right-decor">
            <AngleDecorDouble />
          </div>
        </section>

        {/*** Training info ***/}
        <section className="training-info-section">
          <Swiper
            className="training-slider"
            slidesPerView={1}
            autoHeight={true}
            modules={[Controller]}
            onSlideChange={() => {}}
            onSwiper={setControlledTrainingInfo}
            onSlideChangeTransitionStart={({ slides }) => setPreviewImgPath(handleChangeTrainingType(slides))}
            controller={{ control: controlledTrainingPagination }}
          >
            {trainingList.map(({ id, title, description, image, image_main }) => {
              return (
                <SwiperSlide key={id} className="training-slider__item" data-img-preview={image_main}>
                  <div className="training">
                    <div className="training__image">
                      <Image desk={image} alt={title} />
                    </div>

                    <p className="training__title details-title">{title}</p>

                    <div className="cke-description training__info" dangerouslySetInnerHTML={{ __html: description }} />

                    <Link to={`/schedule/?type=${id}`} className="btn btn--bg training__schedule">
                      К расписанию
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      </div>
    </>
  );
};

export default TrainingPage;
