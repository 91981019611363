export const tabNavigationSliderOptions = {
  slidesPerView: 1,
  breakpoints: {
    744: {
      slidesPerView: 3,
    },
  },
};

export const changeTabNavigationHandler = (slides) => {
  const filteredArr = slides.filter((item) => item.classList.contains('swiper-slide-active'));
  const idVal = filteredArr[0].getAttribute('data-tab-id');

  return +idVal;
};
