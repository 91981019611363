/*** Параметры слайдера ***/
export const birthdaySliderOptions = {
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    576: {
      slidesPerView: 2,
    },
  },
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next',
  },
  pagination: {
    clickable: true,
    pagination: '.swiper-pagination',
  },
};

/*** Конвертировать дату рождения - сегодня/завтра/дата ***/
// формат даты рождения чч.мм.гггг
export const convertBirthdayDate = (date) => {
  const birthdayDay = date.split('.')[0]; // день рождения
  const birthdayMonth = date.split('.')[1]; // месяц рождения

  const currentDate = new Date(); // текущая дата
  const currentDay = currentDate.getDate(); // текущее число
  const currentMonth = currentDate.getMonth() + 1; // текущий месяц

  if (+currentDay === +birthdayDay && currentMonth === +birthdayMonth) {
    return 'сегодня';
  }

  if (+currentDay + 1 === +birthdayDay && currentMonth === +birthdayMonth) {
    return 'завтра';
  }

  return date;
};
