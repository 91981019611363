import React from 'react';

const InformerAbonementAngle = () => {
  return (
    <svg width="64" height="36" viewBox="0 0 64 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2381 9.61914L7.43807 18.0001M7.43807 18.0001L15.2381 26.381M7.43807 18.0001H0.238068"
        stroke="currentColor"
        strokeWidth="1.52381"
      />
      <path d="M64 2H28.9524L13.7143 18L28.9524 34H64" stroke="currentColor" strokeWidth="3.04762" />
    </svg>
  );
};

export default InformerAbonementAngle;
