import React, { createContext, useState, useEffect } from 'react';
import { YMaps, Map, Clusterer, Placemark, FullscreenControl, TypeSelector, ZoomControl } from '@pbe/react-yandex-maps';

// Files
import './YMap.scss';
import mapMarker from './map-point.svg';
import { api } from '../../constants/api';

// Components
import Portal from './Portal';
import BalloonContent from './BalloonContent/BalloonContent';

// Context
export const PortalPropsContext = createContext({});

// Default map state
const mapState = {
  center: [55.751574, 37.573856],
  zoom: 11,
};

const YMap = () => {
  // Portal используется для кастомного компонента внутри балуна
  const [activePortal, setActivePortal] = useState(false); // состояние портала
  const [selectedPointData, setSelectedPointData] = useState({}); // данные выбранной точки
  const [activeMarkerId, setActiveMarkerId] = useState(null); // id выбранной точки
  const [gymListInfo, setGymListInfo] = useState([]); // список залов

  const balloonCloseBtn = document.querySelector('.ymaps-2-1-79-balloon__close'); // кнопка закрытия балуна

  // закрытие балуна при ресайзе и смене ориентации
  window.addEventListener('resize', () => {
    triggerCloseBalloonHandler();
  });

  window.addEventListener('orientationchange', () => {
    triggerCloseBalloonHandler();
  });

  useEffect(() => {
    api
      .get('get_gym_coord/')
      .then(({ data }) => setGymListInfo(data))
      .catch((error) => console.error(error));
  }, []);

  /*** Handlers ***/
  // смена маркера
  const handleMarkerClick = (item) => {
    if (item.id === activeMarkerId) {
      // отменить закрытие балуна при повторном клике на маркер, если его балун открыт
      // e.preventDefault();

      // закрывать балун при повторном клике на маркер, если его балун открыт
      setActivePortal(false);
    }

    setActiveMarkerId(item.id); // установить id активного балуна

    // ставим в очередь промисов, чтобы сработало после отрисовки балуна
    setTimeout(() => {
      setActivePortal(true);
      setSelectedPointData(item);
    }, 0);
  };

  // функция закрытия балуна
  const triggerCloseBalloonHandler = () => {
    if (balloonCloseBtn) balloonCloseBtn.click();

    setActiveMarkerId(null); // отменить выбранный балун. нужно для повторного клика по метке
    setActivePortal(false); // отменить отображение портала
  };

  return (
    <PortalPropsContext.Provider value={{ setActivePortal, setActiveMarkerId }}>
      <YMaps>
        <Map defaultState={mapState}>
          <Clusterer options={{ preset: 'islands#darkGreenClusterIcons' }}>
            {gymListInfo.map((item, index) => {
              return (
                <Placemark
                  key={item.id}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: mapMarker,
                    iconImageSize: [29, 29],
                    iconImageOffset: [-14, -14],
                    hideIconOnBalloonOpen: false, // не прятать метку при открытом балуне
                    balloonOffset: [0.5, 0.5], // смещение балуна
                    balloonPanelMaxMapArea: 483600, // площадь карты, при которой отображается панель (до 744)
                  }}
                  properties={{
                    item: index,
                    // iconContent: '2', // пару символов помещается внутри метки
                    // hintContent: '<b>Текст при наведении на метку</b>',
                    // создаём пустой элемент с заданными размерами для Portal
                    balloonContent: `<div id="balloon-wrapper" data-marker-id=${item.id}></div>`,
                  }}
                  geometry={[item.lat, item.lng]}
                  onClick={() => handleMarkerClick(item)}
                />
              );
            })}
          </Clusterer>

          <FullscreenControl />
          <TypeSelector options={{ float: 'right' }} />
          <ZoomControl options={{ float: 'right' }} />
        </Map>

        {activePortal && (
          <Portal>
            <BalloonContent data={selectedPointData} />
          </Portal>
        )}
      </YMaps>
    </PortalPropsContext.Provider>
  );
};

export default YMap;
