import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

//Files
import './PopupLogout.scss';
import logoImg from '../../assets/images/logo.png';
import { removeAuthStorage } from '../../constants/constants';

// Components
import ClosePopup from '../IconComponents/ClosePopup';

// Context
import { AccountPopupContext, IsLoggedInContext } from '../App/App';

const PopupLogout = ({ setIsMenuOpen }) => {
  const { setIsLoggedIn } = useContext(IsLoggedInContext); // Use user state context
  const { isPopupLogoutOpen, setIsPopupLogoutOpen } = useContext(AccountPopupContext); // Use app account popup context

  /*** Handlers ***/
  const handleClosePopup = () => setIsPopupLogoutOpen(!isPopupLogoutOpen);

  const handleLogout = () => {
    removeAuthStorage();

    setIsMenuOpen(false);
    setIsPopupLogoutOpen(!isPopupLogoutOpen);
    setIsLoggedIn(false);
  };

  return (
    <div className={`popup-logout ${isPopupLogoutOpen ? 'popup-logout--open' : ''}`}>
      <div className="popup-logout__content popup-logout__wrapper">
        <Link className="popup-logout__logo" to="/">
          <img src={logoImg} alt="logo" />
        </Link>

        <button className="popup-logout__close" onClick={handleClosePopup}>
          <ClosePopup />
        </button>

        <p className="popup-logout__text">Выйти из аккаунта?</p>

        <button className="popup-logout__btn" onClick={handleLogout}>
          Выход
        </button>
      </div>
    </div>
  );
};

export default PopupLogout;
