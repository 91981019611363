import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// Files
import { api } from '../../constants/api';
import { removeAuthStorage } from '../../constants/constants';
import './SchedulePage.scss';
import { createScheduleDataObj, weekRange } from './index';

// Components
import ArrowLeft from '../../components/IconComponents/ArrowLeft';
import ArrowRight from '../../components/IconComponents/ArrowRight';
import Metro from '../../components/IconComponents/Metro/Metro';
import Image from '../../components/Image/Image';
import ScheduleClass from './ScheduleClass/ScheduleClass';
import ScheduleClassReviews from './ScheduleClassReviews/ScheduleClassReviews';
import ScheduleFilter from './ScheduleFilter/ScheduleFilter';
import ScheduleFilterMob from './ScheduleFilterMob/ScheduleFilterMob';

// Context
import { IsLoggedInContext } from '../../components/App/App';
export const MenuFilterContext = createContext({}); // контекст меню фильтра
export const ClassContext = createContext({}); // контекст занятия
export const ClassReviewsContext = createContext({}); // контекст отзывов занятия

const SchedulePage = () => {
  const scheduleImgBlk = useRef(null);
  const [schedulePreviewImgHeight, setSchedulePreviewImgHeight] = useState(0); // Set height for schedule img

  // Use context
  const { isLoggedIn, setIsLoggedIn } = useContext(IsLoggedInContext); // Use app user state context

  const mobScreen = window.matchMedia('(max-width: 991px)'); // Mobile media query
  const [isMobScreen, setIsMobScreen] = useState(mobScreen.matches); // State for mobile screen

  // Page
  const [pageInfo, setPageInfo] = useState({}); // Page title and subtitle info state

  // Filter states
  const [trainers, setTrainers] = useState([]); // All trainers filter state
  const [gym, setGym] = useState([]); // All gym filter state
  const [level, setLevel] = useState([]); // Training level filter state
  const [trainingType, setTrainingType] = useState([]); // Training level filter state
  const [week, setWeek] = useState([]); // Week data
  const [weekFilterName, setWeekFilterName] = useState('текущая неделя'); // Week filter name
  const [shiftWeekCounter, setShiftWeekCounter] = useState(0); // Week shift

  const [scheduleInfo, setScheduleInfo] = useState([]); // расписние всех занятий
  const [noClassMsg, setNoClassMsg] = useState(''); // No class message

  // Class reviews popup states
  const [classReviewsPopupOpen, setClassReviewsPopupOpen] = useState(false); // попап отзывов занятия
  const [classReviews, setClassReviews] = useState([]); // отзывы занятия в попапе
  const [classDataPopup, setClassDataPopup] = useState({}); // данные занятия в попапе

  /*** Filter week params ***/
  const [filterParams, setFilterParams] = useSearchParams();
  useEffect(() => {
    if (isLoggedIn) {
      api
        .get('get_my_id/')
        .then(({ data }) => localStorage.setItem('user', JSON.stringify(data)))
        .catch(({ response }) => {
          console.log(response);
        });
    }
  }, [isLoggedIn]);
  // Get default page info on page load
  useEffect(() => {
    // Set page info
    api.get('schedule-page/').then(({ data }) => setPageInfo(data));

    /*** Set filter week date ***/
    const weekRangeArr = weekRange(); // Current week range
    const startDate = `${weekRangeArr[0].year}-${weekRangeArr[0].month}-${weekRangeArr[0].day}`; // Current start date
    let endDate;

    if (+weekRangeArr[6].day === 31) {
      endDate = `${weekRangeArr[6].year}-${weekRangeArr[6].month}-${+weekRangeArr[6].day}`; // Current end date
    } else {
      endDate = `${weekRangeArr[6].year}-${weekRangeArr[6].month}-${+weekRangeArr[6].day}`; // Current end date
    }
    setWeek(weekRangeArr); // Set current w§eek data

    // Check if NOT search params - make new str. week params for request
    if (filterParams.toString() === '') {
      const filterWeekObj = {
        start_date: startDate,
        end_date: endDate,
      };

      // Сформировать строку для запроса
      const requestParams = Object.entries(filterWeekObj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      setFilterParams(filterWeekObj); // Установить параметры в адресную строку

      // Получение всех занятий
      api.get(`klass/?${requestParams}`).then(({ data }) => {
        const filteredClass = data.filter(({ is_published }) => is_published === true);

        data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

        setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
      });
    } else if (!(filterParams.has('start_date') && filterParams.has('end_date'))) {
      // если параметры есть, но не для недели
      // Создать новый объект с параметрами
      const newParamsObj = {};

      // Взять существующие параметры и сформировать новый объект
      for (let [key, value] of filterParams.entries()) {
        newParamsObj[key] = value;
      }

      // Добавить новые параметры недели в объект фильтра
      newParamsObj['start_date'] = startDate;
      newParamsObj['end_date'] = endDate;

      // Сформировать строку для запроса
      const requestParams = Object.entries(newParamsObj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      setFilterParams(newParamsObj); // Установить параметры в адресную строку

      // Получение всех занятий
      api.get(`klass/?${requestParams}`).then(({ data }) => {
        const filteredClass = data.filter(({ is_published }) => is_published === true);

        data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

        setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
      });
    } else {
      // Если есть параметры недели и любые другие или только для недели
      // установить смещение недели
      const startDateParams = filterParams.get('start_date'); // Начало недели из параметров
      const endDateParams = filterParams.get('end_date'); // Конец недели из параметров
      const millisecondsInWeek = 24 * 60 * 60 * 1000; // Количество миллисек в дне
      const weekShift = (Date.parse(startDateParams) - Date.parse(startDate)) / millisecondsInWeek; // Разница в днях
      // Массив коротких названий месяцев
      const shortMonthNamesArr = ['Янв', 'Фев', 'Мар', 'Апр', 'Мая', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

      // Начало и конец недели для кнопки фильтра
      const newStartDateFilterNameArr = startDateParams.split('-').slice(1).reverse();
      const newStartDate = `${newStartDateFilterNameArr[0]} ${shortMonthNamesArr[+newStartDateFilterNameArr[1] - 1]}`;

      const endDateArr = endDateParams.split('-').slice(1).reverse(); // Массив даты конца недели
      const newEndDate = `${endDateArr[0]} ${shortMonthNamesArr[+endDateArr[1] - 1]}`;

      setShiftWeekCounter(weekShift); // Set new week shift state
      setWeekFilterName(`${newStartDate} - ${newEndDate}`); // Set filter week name
      setWeek(weekRange(weekShift)); // Set week data form params on load page

      // Создать новый объект с параметрами
      const newParamsObj = {};

      // Взять существующие параметры и сформировать новый объект
      for (let [key, value] of filterParams.entries()) {
        newParamsObj[key] = value;
      }

      // Сформировать строку для запроса
      const requestParams = Object.entries(newParamsObj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      // Получение всех занятий
      api.get(`klass/?${requestParams}`).then(({ data }) => {
        const filteredClass = data.filter(({ is_published }) => is_published === true);

        data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

        setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
      });
    }

    // Check screen width for mobile
    const handleScreenChange = (event) => setIsMobScreen(event.matches);
    mobScreen.addEventListener('change', handleScreenChange);
    return () => mobScreen.removeEventListener('change', handleScreenChange);
  }, [weekFilterName, isLoggedIn]);

  // Set filter sort
  useEffect(() => {
    // Get trainers, filter by active and sort by by admin settings. Set trainers list
    api.get(`trainers/?ordering=${pageInfo.ordering_trainers}`).then(({ data }) => {
      const filterSortedTrainers = data.filter(({ active }) => active === true);

      setTrainers(filterSortedTrainers);
    });

    // Get gym, filter by published and sort by admin settings. Set gym list
    api.get(`gym/?ordering=${pageInfo.ordering_gyms}`).then(({ data }) => {
      const filterSortedGym = data.filter(({ is_published }) => is_published === true);

      setGym(filterSortedGym);
    });

    // Get training level, filter by published and sort by admin settings. Set training level
    api.get(`player-level/?ordering=${pageInfo.ordering_player_levels}`).then(({ data }) => {
      const filterSortedLevel = data.filter(({ is_published }) => is_published === true);

      setLevel(filterSortedLevel);
    });

    // Get type training, filter by published and sort by admin settings. Set type training list
    api.get(`type-training/?ordering=${pageInfo.ordering_training_types}`).then(({ data }) => {
      const filterSortedTrainingType = data.filter(({ is_published }) => is_published === true);

      setTrainingType(filterSortedTrainingType);
    });
  }, [pageInfo]);

  // Set preview image height
  useEffect(() => {
    setSchedulePreviewImgHeight(scheduleImgBlk.current.clientHeight);
  }, [level]);

  /*** Handlers ***/
  const handleChangeWeek = (changedWeekArray) => {
    let requestParams = '';

    // Start and end week for request
    const startDate = `${changedWeekArray[0].year}-${changedWeekArray[0].month}-${changedWeekArray[0].day}`;
    let lastDayWeek;

    // Баг фикс неправильной end_date. +1 быть не должно, но из-за недоработки на бэке сделали такой костыль
    // с добавлением 1. проблема на бэке с переходом на другой месяц и год
    if (+changedWeekArray[6].day === 31) {
      lastDayWeek = +changedWeekArray[6].day + 1 < 10 ? `0${+changedWeekArray[6].day + 1}` : +changedWeekArray[6].day;
    } else {
      lastDayWeek = +changedWeekArray[6].day + 1 < 10 ? `0${+changedWeekArray[6].day + 1}` : +changedWeekArray[6].day;
    }

    const endDate = `${changedWeekArray[6].year}-${changedWeekArray[6].month}-${lastDayWeek}`;
    console.log(lastDayWeek);

    // Start and end week for filter name
    const startWeek = `${changedWeekArray[0].day} ${changedWeekArray[0].monthNameShort}`;
    const endWeek = `${changedWeekArray[6].day} ${changedWeekArray[6].monthNameShort}`;

    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    // Добавить новые параметры недели в объект фильтра
    newParamsObj['start_date'] = startDate;
    newParamsObj['end_date'] = endDate;
    setFilterParams(newParamsObj);
    setWeekFilterName(`${startWeek} - ${endWeek}`);

    // Сформировать строку для запроса
    requestParams = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    requestParams = `?${requestParams}`;

    // Получение всех занятий
    api.get(`klass/${requestParams}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };
  return (
    <MenuFilterContext.Provider
      value={{ trainers, gym, level, trainingType, filterParams, setFilterParams, setNoClassMsg, setScheduleInfo }}
    >
      <ClassReviewsContext.Provider
        value={{
          classReviewsPopupOpen,
          setClassReviewsPopupOpen,
          classReviews,
          setClassReviews,
          classDataPopup,
          setClassDataPopup,
        }}
      >
        <div className="container">
          {/*** Page heading ***/}
          <h1 className="page-title">{pageInfo.title}</h1>

          <div className="schedule-subtitle-wrapper">
            <div className="page-subtitle">
              <div className="schedule-subtitle-image" style={{ height: schedulePreviewImgHeight }}>
                <Image mob={pageInfo.image_mob} desk={pageInfo.image} />
              </div>
            </div>

            <ul className="subtitle-level" ref={scheduleImgBlk}>
              {level.length > 0 &&
                level.map(({ id, title, color }) => {
                  return (
                    <li className="subtitle-level__item" key={id}>
                      <div className="subtitle-level__item-image" style={{ borderColor: color }} />

                      <div className="subtitle-level__item-title">{`${title} уровень`}</div>
                    </li>
                  );
                })}
            </ul>
          </div>

          {/*** Schedule filter ***/}
          <section className="schedule">
            <div className="schedule__filter">
              {isMobScreen ? <ScheduleFilterMob /> : <ScheduleFilter />}

              {/* Week filter */}
              <div className="filter-week">
                <button
                  className="filter-week__btn filter-week__btn--prev"
                  onClick={() => {
                    const prevWeekRangeArr = weekRange(shiftWeekCounter - 7);

                    handleChangeWeek(prevWeekRangeArr); // Change week

                    setWeek(prevWeekRangeArr); // Set prev week data
                    setShiftWeekCounter((prevState) => prevState - 7); // Set new week shift state
                  }}
                >
                  <ArrowLeft />
                </button>

                <div className="filter-week__text">{shiftWeekCounter === 0 ? 'текущая неделя' : weekFilterName}</div>

                <button
                  className="filter-week__btn filter-week__btn--next"
                  onClick={() => {
                    const nextWeekRangeArr = weekRange(shiftWeekCounter + 7);

                    handleChangeWeek(nextWeekRangeArr); // Change week

                    setWeek(nextWeekRangeArr); // Set next week data
                    setShiftWeekCounter((prevState) => prevState + 7); // Set new week shift state
                  }}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>

            {/*** Schedule content ***/}
            <div className="schedule__table">
              {/* Schedule table header */}
              <div className="schedule__header">
                <div className="schedule__row">
                  <div className="schedule__column">
                    <span>
                      {week[0]?.monthName}
                      {week[0]?.monthName !== week[6]?.monthName && ` - ${week[6]?.monthName}`}
                    </span>
                  </div>

                  {week.map(({ id, dayNameShort, day, monthNameShort }) => {
                    return (
                      <div className="schedule__column" key={id}>
                        <span>{dayNameShort}</span>
                        <span>{`${day} ${monthNameShort}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Schedule table body */}
              <div className="schedule__body">
                {/* No class message */}
                {noClassMsg && <div className="schedule__row no-class">{noClassMsg}</div>}

                {/* Class data */}
                {Object.entries(scheduleInfo)
                  .sort()
                  .map(([gymName, gymClass], index) => {
                    return (
                      <div className="schedule__row" key={index}>
                        {/* Вывести название зала */}
                        <div className="schedule__column">
                          <div className="schedule__column-icon">
                            <Metro />
                          </div>

                          {/* Название станции метро */}
                          <span style={{ color: gymClass.color, wordBreak: 'initial' }}>{gymName}</span>
                        </div>

                        {/* Вывести занятия на неделе для этого зала */}
                        {gymClass.classes.map((dayClass, index) => {
                          return (
                            <div className="schedule__column" key={index}>
                              {/* Вывести занятия в этот день для этого зала */}
                              {dayClass.map((dayClassItem, index) => (
                                <ScheduleClass
                                  classData={dayClassItem}
                                  key={dayClassItem.date + index}
                                  isEndedWeek={shiftWeekCounter}
                                />
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>

          {/*** Class reviews popup ***/}
          <ScheduleClassReviews />
        </div>
      </ClassReviewsContext.Provider>
    </MenuFilterContext.Provider>
  );
};

export default SchedulePage;
