import React from 'react';

// Files
import metroIcon from './metro-icon.png';

const Metro = () => {
  return <img src={metroIcon} alt="metro-icon" title="metro-icon" />;
};

export default Metro;
