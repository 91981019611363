import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

// Files
import './BlogPage.scss';
import { api } from '../../constants/api';

// Components
import ArticleCard from './ArticleCard/ArticleCard';

function BlogPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [articlesCount, setArticlesCount] = useState(0);

  const pageSize = 3;

  useEffect(() => {
    api.get(`post/?page=${currentPage}`).then(({ data }) => {
      setArticlesCount(data.count);
      setArticles(data.results);
      setTotalPages(Math.ceil(data.count / pageSize));
    });
  }, [currentPage]);

  return (
    <div className="container">
      <section className="articles">
        <h1 className="page-title">Блог</h1>

        <ul className="articles-list">
          {articles.map((article) => {
            return (
              <li className="articles-list__item" key={article.id}>
                <ArticleCard article={article} />
              </li>
            );
          })}
        </ul>

        {articlesCount > 3 && (
          <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={({ selected }) => setCurrentPage(selected + 1)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        )}
      </section>
    </div>
  );
}

export default BlogPage;
