import React, { useContext, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

// Context
import { PortalPropsContext } from './YMap';

const Portal = ({ children }) => {
  const { setActivePortal, setActiveMarkerId } = useContext(PortalPropsContext);

  // находим искомый HTML по id
  const mount = document.getElementById('balloon-wrapper'); // обертка балуна

  useLayoutEffect(() => {
    const balloonCloseBtn = document.querySelector('.ymaps-2-1-79-balloon__close'); // кнопка закрытия балуна

    // для tablet и desk балун отличается от mob
    if (window.innerWidth >= 744) {
      const mountParent = mount.closest('.ymaps-2-1-79-balloon'); // родитель балуна
      const mountHeight = mount.clientHeight; // высота балуна после рендера

      mountParent.style.top = `-${mountHeight + 10}px`; // фикс позиционирования балуна
    }

    // события закрытия балуна для десктопа и мобла
    if (balloonCloseBtn) {
      balloonCloseBtn.addEventListener('click', () => {
        setActiveMarkerId(null); // отменить выбранный балун. нужно для повторного клика по метке
        setActivePortal(false); // отменить отображение портала
      });

      balloonCloseBtn.addEventListener('touchend', () => {
        setActiveMarkerId(null); // отменить выбранный балун. нужно для повторного клика по метке
        setActivePortal(false); // отменить отображение портала
      });
    }
  }, [mount]);

  // отменяем отрисовку при отсутствии искомого элемента
  if (!mount) return null;

  return createPortal(children, mount);
};

export default Portal;
