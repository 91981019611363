import React from 'react';

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="127" height="149" viewBox="0 0 127 149" fill="none">
      <path d="M52.5 67H89.5V46.5L127 74.5L89.5 102V81.5H52.5V67Z" fill="currentColor" />
      <path
        d="M104.5 38.5V14.5H15V133.5H104.5V110L119.5 98.5V134C119.5 142.284 112.784 149 104.5 149H15C6.71573 149 0 142.284 0 134V15C0 6.71572 6.71573 0 15 0H104.5C112.784 0 119.5 6.71573 119.5 15V49.5L104.5 38.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoutIcon;
