import React, { useEffect, useState, createContext, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';

// Files
import './GymPage.scss';
import { api, apiHostName } from '../../constants/api';
import { toggleBodyScrollHandler } from '../../constants/constants';

// Components
import TopCardsPreviewSlider from '../../components/TopCardsPreviewSlider/TopCardsPreviewSlider';
import Rating from '../../components/Rating/Rating';
import TabNavigationSlider from '../../components/TabNavigationSlider/TabNavigationSlider';
import SliderThumbsBottom from '../../components/SliderThumbsBottom/SliderThumbsBottom';
import Review from '../../components/Review/Review';
import YMap from '../../components/YMap/YMap';
import CommentPopup from '../../components/CommentPopup/CommentPopup';

// Context
import { AccountPopupContext, AddCommentPopupContext, IsLoggedInContext } from '../../components/App/App';
export const SetGymIdContext = createContext({});
export const SetSwitchTabNavigationContext = createContext({});

const GymPage = () => {
  // Use context
  const { isLoggedIn } = useContext(IsLoggedInContext);
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext);
  const { setAddCommentPopupOpen, setIsCommentPopupForm } = useContext(AddCommentPopupContext);

  const gymScrollRef = useRef(null);

  // Page
  const [pageInfo, setPageInfo] = useState({}); // Page title and subtitle

  // Gym
  const [gymList, setGymList] = useState([]); // Top slider with all gym
  const [activeGymId, setActiveGymId] = useState(null); // Selected gym id
  const [activeGym, setActiveGym] = useState([]); // Selected gym data
  const [activeGymSlides, setActiveGymSlides] = useState([]); // Selected gym slides
  const [activeGymReviews, setActiveGymReviews] = useState([]); // Selected gym reviews

  // Selected gym details
  const [details, setDetails] = useState([]); // Gym details (tabs block. navigation and content)
  const [detailsNavigationId, setDetailsNavigationId] = useState(null); // Active details(tab) id
  const [detailsContent, setDetailsContent] = useState([]); // Active details(tab) content

  // Get default page info and gym data on page load
  useEffect(() => {
    // Set page info
    api.get('dynamic-page/gym/').then(({ data }) => {
      const pageInfo = {
        title: data.title,
        description: data.blocks[0].content,
      };

      setPageInfo(pageInfo);
    });

    // Get order gym and set ordered gym data
    api.get('gym-page/').then(({ data }) => {
      api.get(`gym/?ordering=${data.ordering_gyms}`).then(({ data }) => {
        const filterSortedGym = data.filter(({ is_published }) => is_published === true);

        setGymList(filterSortedGym); // Set gym list
        setActiveGymId(filterSortedGym[0].id); // Set the first active gym id
      });
    });

    window.scrollTo({ top: 0, behavior: 'auto' }); // скролл в начало страницы
  }, []);

  // Change gym
  useEffect(() => {
    if (activeGymId) {
      api.get(`gym/${activeGymId}/`).then(({ data }) => {
        // Parse content for the first tab in details of active gym
        const defaultTabContent = data.blocks[0]?.style_content;
        const defaultTabNav = data.blocks[0]?.id; // Gey the first active tab of active gym by default
        const filteredActiveGymReviews = data.gym_reviews.filter(({ is_published }) => is_published === true);

        setActiveGym(data); // Set gym data
        setActiveGymSlides(data.gym_slides); // Set active gym slides
        setActiveGymReviews(filteredActiveGymReviews); // Set active gym reviews
        setDetails(data.blocks); // Set all details of the gym (all tabs content)
        setDetailsNavigationId(defaultTabNav); // Set the first active tab navigation
        setDetailsContent(defaultTabContent); // Set the first tab content
      });
    }
  }, [activeGymId]);

  // Change details tab
  useEffect(() => {
    details.forEach(({ id, style_content }) => {
      if (id === detailsNavigationId) setDetailsContent(style_content); // Set current tab content
    });
  }, [detailsNavigationId]);

  return (
    <SetSwitchTabNavigationContext.Provider value={{ detailsNavigationId, setDetailsNavigationId }}>
      <SetGymIdContext.Provider value={{ activeGymId, setActiveGymId }}>
        <div className="container">
          <h1 className="page-title page-title--gym">{pageInfo.title}</h1>
          <p className="page-subtitle">{pageInfo.description}</p>

          {/*** Top cards preview slider ***/}
          <section className="gym-types">
            <TopCardsPreviewSlider scrollTarget={gymScrollRef} data={gymList} />
          </section>

          {/*** Active gym info ***/}
          <section className="gym" ref={gymScrollRef}>
            <div className="gym__images">
              <SliderThumbsBottom slides={activeGymSlides} />
            </div>

            <div className="gym__title">
              <p className="details-title">{activeGym.name}</p>

              {/* временно скрыто */}
              {/*<div className="gym__rating">*/}
              {/*  <Rating item={activeGym} />*/}
              {/*</div>*/}
            </div>

            <div className="gym__info">
              <div className="gym__info-navigation">
                {/* Details navigation slider */}
                <TabNavigationSlider data={details} />
              </div>

              <div className="cke-description" dangerouslySetInnerHTML={{ __html: detailsContent }} />
            </div>

            {/* К расписанию */}
            <Link to={`/schedule/?gym=${activeGymId}`} className="btn btn--bg gym__schedule">
              К расписанию
            </Link>
          </section>

          {/*** Active gym video ***/}
          {activeGym.video_file && (
            <section className="gym-video">
              <div className="gym-video__wrapper">
                <video src={`${apiHostName}${activeGym.video_file}`} autoPlay muted controls loop />
              </div>
            </section>
          )}

          {/*** Gym reviews ***/}
          {/* временно скрыто, потом надо будет вернуть */}
          {/*<section className="gym-reviews">*/}
          {/*  <h2 className="page-title">Отзывы</h2>*/}

          {/*  {activeGymReviews.length > 0 ? (*/}
          {/*    <ul className="reviews">*/}
          {/*      {activeGymReviews.map((item) => {*/}
          {/*        return (*/}
          {/*          <li key={item.id} className="reviews__item">*/}
          {/*            <Review item={item} />*/}
          {/*          </li>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </ul>*/}
          {/*  ) : (*/}
          {/*    <p className="no-reviews">Отзывов для этого зала нет</p>*/}
          {/*  )}*/}

          {/*  <button*/}
          {/*    className="btn btn--bg add-review"*/}
          {/*    onClick={() => {*/}
          {/*      if (!isLoggedIn) {*/}
          {/*        setIsPopupAccountOpen(true);*/}
          {/*        return;*/}
          {/*      }*/}

          {/*      toggleBodyScrollHandler();*/}

          {/*      setIsCommentPopupForm(true);*/}
          {/*      setAddCommentPopupOpen(true);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    написать отзыв*/}
          {/*  </button>*/}
          {/*</section>*/}
        </div>

        {/*** Gym map ***/}
        <section className="gym-map map">
          <YMap />
        </section>

        {/*** Comment popup ***/}
        <CommentPopup previousComments={activeGymReviews} target={'g'} targetId={activeGymId} />
      </SetGymIdContext.Provider>
    </SetSwitchTabNavigationContext.Provider>
  );
};

export default GymPage;
