import React, { useState } from 'react';

// Files
import './ArticleCard.scss';

// Components
import Image from '../../Image/Image';

function ArticleCard({ article }) {
  const [articleStates, setArticleStates] = useState({
    index: {
      expanded: false,
    },
  });

  // формат даты
  const createdAt = article.created_at;
  const date = new Date(createdAt);
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;

  /*** Handlers ***/
  const handleExpand = () => {
    const newArticleStates = { ...articleStates };

    newArticleStates.index.expanded = !newArticleStates.index.expanded;
    setArticleStates(newArticleStates);
  };

  return (
    <div className="article">
      <div className="article__image">
        <Image desk={article.image} alt={'article'} />
      </div>

      <div className="article__container">
        <h2 className="article__title">{article.title}</h2>

        <p className="article__date">{formattedDate}</p>

        <div
          dangerouslySetInnerHTML={{ __html: article.content }}
          className={`article__content  ${articleStates.index.expanded ? 'expanded' : ''}`}
        />

        <button type="button" className="article__btn" onClick={handleExpand}>
          {articleStates.index.expanded ? 'Скрыть' : 'Посмотреть всё'}
        </button>
      </div>
    </div>
  );
}

export default ArticleCard;
