import React, { useState, useEffect } from 'react';

// Files
import './RulesBlock.scss';
import { api } from '../../../constants/api';
import open from '../../../assets/icon/rules-open.svg';
import close from '../../../assets/icon/rules-close.svg';

function RulesBlock() {
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    api
      .get('dynamic-page/registration/')
      .then(({ data }) => setBlocks(data.blocks))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);
  const itemsLength = blocks.length;
  const halfItemsLength = Math.ceil(itemsLength / 2); // округляем до ближайшего целого числа вверх

  const firstColumnItems = blocks.slice(0, halfItemsLength); //левый столбец правил
  const secondColumnItems = blocks.slice(halfItemsLength, itemsLength); //правый столбец правил

  const onTitleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="rules-block">
      <h2 className="rules-block__title">Правила школы</h2>

      <div className="rules-block__container">
        <div className="rules-block__column">
          {firstColumnItems.map(({ id, title, style_content }, index) => (
            <div className={`rules-block__content ${activeIndex === index ? 'open' : ''}`} key={id}>
              <div className="rules-block__header" onClick={() => onTitleClick(index)}>
                <img src={activeIndex === index ? close : open} alt={activeIndex === index ? 'close' : 'open'} />

                <h3 className="rules-block__subtitle">{title}</h3>
              </div>

              {style_content && (
                <div
                  className="cke-description rules-block__list"
                  dangerouslySetInnerHTML={{ __html: style_content }}
                />
              )}
            </div>
          ))}
        </div>

        <div className="rules-block__column">
          {secondColumnItems.map(({ id, title, style_content }, index) => (
            <div className={`rules-block__content ${activeIndex === index + halfItemsLength ? 'open' : ''}`} key={id}>
              <div className="rules-block__header" onClick={() => onTitleClick(index + halfItemsLength)}>
                <img
                  src={activeIndex === index + halfItemsLength ? close : open}
                  alt={activeIndex === index + halfItemsLength ? 'close' : 'open'}
                />

                <h3 className="rules-block__subtitle">{title}</h3>
              </div>

              {style_content && (
                <div
                  className="cke-description rules-block__list"
                  dangerouslySetInnerHTML={{ __html: style_content }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default RulesBlock;
