import React, { useEffect, useRef, useState } from 'react';

// Files
import './Review.scss';
import authorNoImage from './author-no-image.png';
import { formatDateReviews } from '../../constants/constants';

// Components
import Rating from '../../components/Rating/Rating';
import Image from '../Image/Image';

function Review({ item }) {
  const mobScreen = window.matchMedia('(max-width: 743px)'); // Mobile media query
  const [isMobScreen, setIsMobScreen] = useState(mobScreen.matches); // State for mobile screen
  const [isExpandedReview, setIsExpendedReview] = useState(false);
  const contentRef = useRef(20); // высота отзыва по-умолчанию

  const { author, content, created_at } = item;

  // высота элемента с отзывом
  const heightStyle = isExpandedReview ? { height: contentRef.current.clientHeight } : { height: 20 };
  // класс для элемента с отзывом
  const collapsingContentClass = contentRef.current.clientHeight > 20 ? 'collapsing' : '';

  // Check screen width for mobile
  useEffect(() => {
    const handleScreenChange = (event) => setIsMobScreen(event.matches);

    mobScreen.addEventListener('change', handleScreenChange);

    return () => mobScreen.removeEventListener('change', handleScreenChange);
  }, []);

  /*** Handlers ***/
  const handleExpand = () => setIsExpendedReview(!isExpandedReview);

  return (
    <div className="review">
      <div className="review__header">
        <div className="review__avatar">
          {author.avatar ? (
            <Image desk={author.avatar} alt="author-image" />
          ) : (
            <img src={authorNoImage} alt="no-author-image" />
          )}
        </div>

        {isMobScreen && <Rating item={item} />}
      </div>

      <div className="review__body">
        <div className="review__title">
          <h3 className="review__author-name">{`${author.first_name} ${author.last_name}`}</h3>
          <span>{formatDateReviews(created_at)}</span>

          {!isMobScreen && <Rating item={item} />}
        </div>

        <div className={`review__text-content ${collapsingContentClass}`} style={heightStyle}>
          <p ref={contentRef}>{content}</p>
        </div>

        {contentRef.current.clientHeight > 20 && (
          <button className="review__show-more" onClick={handleExpand}>
            {!isExpandedReview ? 'Читать далее' : 'Свернуть'}
          </button>
        )}
      </div>
    </div>
  );
}

export default Review;
