import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Files
import './Overlay.scss';
import logo from './logo.png';

// Components
import InformerAbonementAngle from '../IconComponents/InformerAbonementAngle';
import InformerAbonementIcon from '../IconComponents/InformerAbonementIcon';
import CloseMenu from '../IconComponents/CloseMenu';
import Social from '../Social/Social';
import DropDownIcon from '../IconComponents/DropDownIcon';
import UserIcon from '../IconComponents/UserIcon';
import LogoutIcon from '../IconComponents/LogoutIcon';

// Context
import { AccountPopupContext, IsLoggedInContext, MenuAndIconsContext } from '../App/App';

const Overlay = ({ isMenuOpen, setIsMenuOpen, abonementInformer, abonementInformerClass }) => {
  const { isLoggedIn } = useContext(IsLoggedInContext); // Use user state context
  const { isPopupAccountOpen, setIsPopupAccountOpen, isPopupLogoutOpen, setIsPopupLogoutOpen } =
    useContext(AccountPopupContext); // Use app account popup context
  const { menu } = useContext(MenuAndIconsContext); // Use app menu context

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const accessToken = localStorage.getItem('access_token');
  const linkUp = menu.filter(({ position }) => position === 'u');
  const dropdownLink = linkUp.filter(({ children }) => children.length !== 0); //массивы с children
  const headerLink = linkUp.filter(({ children }) => children.length === 0); //пункты хедера без тренировок

  /*** Handlers ***/
  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = () => setIsMenuOpen(!isMenuOpen);
  const handleLogin = (e) => {
    e.preventDefault();

    handleClose();

    accessToken ? navigate('/') : setIsPopupAccountOpen(!isPopupAccountOpen);
  };
  const handleLogout = (e) => {
    e.preventDefault();

    setIsPopupLogoutOpen(!isPopupLogoutOpen);
  };

  return (
    <div className={`overlay ${isMenuOpen ? 'overlay--open' : ''}`}>
      <div className="container">
        <div className="overlay__header">
          <div className="overlay__logo">
            <img src={logo} alt="logo" title="logo" />
          </div>

          <div className="overlay__close" onClick={handleClose}>
            <CloseMenu />
          </div>
        </div>

        <div className="overlay__body">
          <nav className="overlay__nav">
            <button className="accordion__btn" onClick={handleToggle}>
              {dropdownLink.length > 0 && dropdownLink[0].title}

              <span className={`accordion__btn-icon ${isOpen ? 'open' : ''}`}>
                <DropDownIcon />
              </span>
            </button>

            {dropdownLink &&
              dropdownLink.map(({ slug, children }) => {
                const filteredChildren = children.filter(({ is_published }) => is_published === true);

                return (
                  <ul key={slug} className={`accordion ${isOpen ? 'accordion--active' : ''}`}>
                    {filteredChildren.map(({ slug, title }) => {
                      return (
                        <li className="accordion__item" key={slug}>
                          <Link to={slug} onClick={handleClose}>
                            {title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}

            <ul className="overlay__list">
              {headerLink.map(({ id, slug, title }) => (
                <li className="overlay__list-item" key={id}>
                  <Link to={slug} onClick={handleClose}>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="overlay__footer">
          <div className="overlay__icons">
            {/*** Social link ***/}
            {/*<Social />*/}

            {/*** Login/Account ***/}
            {/*{!isLoggedIn ? (*/}
            {/*  <Link to="#" className="overlay__icons-user" onClick={(e) => handleLogin(e)}>*/}
            {/*    <UserIcon />*/}
            {/*  </Link>*/}
            {/*) : (*/}
            {/*  <Link to="/account/" className="overlay__icons-user" onClick={handleClose}>*/}
            {/*    <UserIcon />*/}
            {/*  </Link>*/}
            {/*)}*/}

            {/*** Logout ***/}
            {/*{isLoggedIn && (*/}
            {/*  <Link to="#" className="overlay__icons-logout" onClick={(e) => handleLogout(e)}>*/}
            {/*    <LogoutIcon />*/}
            {/*  </Link>*/}
            {/*)}*/}

            {/*** User abonement informer ***/}
            {isLoggedIn && (
              <div className={`abonement-informer abonement-informer--mob-menu ${abonementInformerClass}`}>
                <div className="abonement-informer__icon abonement-informer__icon--left">
                  <InformerAbonementAngle />
                </div>

                <Link to="/payment/" className="abonement-informer__link" onClick={handleClose}>
                  <div className="abonement-informer__link-icon">
                    <InformerAbonementIcon />
                  </div>

                  <div className="abonement-informer__link-text">{abonementInformer}</div>
                </Link>

                <div className="abonement-informer__icon abonement-informer__icon--right">
                  <InformerAbonementAngle />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
