import React from 'react';

// Files
import './AppPageTopDecor.scss';
import img from './background-top.png';

// Components
import Image from '../../Image/Image';

const AppPageTopDecor = () => {
  return (
    <div className="app-page-top-decor">
      <Image desk={img} alt={'page-top-decor'} title="page-top-decor" />
    </div>
  );
};

export default AppPageTopDecor;
