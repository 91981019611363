/*** Format training history data ***/
export const formatTrainingHistory = (trainingHistoryList) => {
  const monthList = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  const trainingHistoryDataObj = {}; // create new history obj

  // перебрать список тренировок и сформировать новую структуру
  trainingHistoryList.forEach((item) => {
    const classMonth = new Date(item.date).getMonth(); // начинается с 0
    const classYear = new Date(item.date).getFullYear();
    const keyName = `${monthList[classMonth]}, ${classYear}`; // имя ключа нового объекта данных истории тренировок

    // формирование новой структуры данных
    if (!trainingHistoryDataObj[keyName]) {
      trainingHistoryDataObj[keyName] = [item];
    } else {
      trainingHistoryDataObj[keyName].push(item);
    }
  });

  return trainingHistoryDataObj;
};
