import React, { useContext, useEffect, useState } from 'react';
import { Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

// Files
import './ScheduleFilterMob.scss';
import { createScheduleDataObj } from '../index';
import { api } from '../../../constants/api';

// Components
import CloseMenu from '../../../components/IconComponents/CloseMenu';
import DropDownIcon from '../../../components/IconComponents/DropDownIcon';

// Context
import { MenuFilterContext } from '../SchedulePage';

const ScheduleFilterMob = () => {
  // Get data for menu filter from context
  const { trainers, gym, level, trainingType, filterParams, setFilterParams, setNoClassMsg, setScheduleInfo } =
    useContext(MenuFilterContext);

  // Pseudo api data for gender
  const gender = [
    { id: 1, title: 'a', name: 'Общий' },
    { id: 2, title: 'm', name: 'Мужской' },
    { id: 3, title: 'f', name: 'Женский' },
  ];

  /*** Filter menu states ***/
  // Reset filter button
  const [isResetFilterBtn, setIsResetFilterBtn] = useState(false);

  // Selected
  const [gymSelectedMob, setGymSelectedMob] = useState('');
  const [typeSelectedMob, setTypeSelectedMob] = useState('');
  const [trainerSelectedMob, setTrainerSelectedMob] = useState('');
  const [levelSelectedMob, setLevelSelectedMob] = useState('');

  // Trainer menu and active trainer filter state
  const [trainersMenuId, setTrainersMenuId] = useState(null);
  const [trainersMenuAll, setTrainersMenuAll] = useState(true);

  // Gym menu and active gym filter state
  const [gymMenuId, setGymMenuId] = useState(null);
  const [gymMenuIdAll, setGymMenuIdAll] = useState(true);

  // Level menu and active level filter state
  const [levelMenuId, setLevelMenuId] = useState(null);
  const [levelMenuIdAll, setLevelMenuIdAll] = useState(true);

  // Training menu and active training type filter state
  const [trainingTypeMenuId, setTrainingTypeMenuId] = useState(null);
  const [trainingTypeMenuIdAll, setTrainingTypeMenuIdAll] = useState(true);

  // Gender menu and active gender filter state
  const [genderMenuId, setGenderMenuId] = useState(null);
  const [genderMenuIdAll, setGenderMenuIdAll] = useState(true);

  // Side menu state
  const [filterSideMenu, setFilterSideMenu] = useState(false);

  useEffect(() => {
    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    // Hide reset filter btn
    if (Object.keys(newParamsObj).length <= 2) {
      setIsResetFilterBtn(false); // Hide reset filter btn
    } else {
      setIsResetFilterBtn(true); // Show reset filter btn

      // mark selected gyms
      if (Object.keys(newParamsObj).includes('gym__in')) {
        setGymMenuIdAll(false);
        setGymSelectedMob(newParamsObj.gym__in);
      }

      // mark selected type trainings
      if (Object.keys(newParamsObj).includes('type__in')) {
        setTrainingTypeMenuIdAll(false);
        setTypeSelectedMob(newParamsObj.type__in);
      }

      // mark selected trainers
      if (Object.keys(newParamsObj).includes('trainer__in')) {
        setTrainersMenuAll(false);
        setTrainerSelectedMob(newParamsObj.trainer__in);
      }

      // mark selected level
      if (Object.keys(newParamsObj).includes('level__in')) {
        setLevelMenuIdAll(false);
        setLevelSelectedMob(newParamsObj.level__in);
      }
    }

    // Show reset btn after redirect from trainers page
    setTimeout(() => {
      if (Object.keys(newParamsObj).length === 1 && Object.keys(newParamsObj)[0] === 'trainer__in') {
        setIsResetFilterBtn(true); // Show reset filter btn
        setTrainerSelectedMob(newParamsObj.trainer__in); // Set active trainer in filter menu
        setTrainersMenuAll(false);
      }
    }, 0);
  }, []);

  useEffect(() => {
    // Set selected trainer id if it's in params
    if (filterParams.has('trainer') && trainers.length > 0) {
      const paramValue = filterParams.get('trainer');
      const activeTrainerObject = trainers.filter(({ id }) => +id === +paramValue)[0];

      setTrainersMenuId(activeTrainerObject.id);
      setTrainersMenuAll(false);
    }

    // Set selected gym id if it's in params
    if (filterParams.has('gym') && gym.length > 0) {
      const paramValue = filterParams.get('gym');
      const activeGymObject = gym.filter(({ id }) => +id === +paramValue)[0];

      setGymMenuId(activeGymObject.id);
      setGymMenuIdAll(false);
    }

    // Set selected level id if it's in params
    if (filterParams.has('level') && level.length > 0) {
      const paramValue = filterParams.get('level');
      const activeLevelObject = level.filter(({ id }) => +id === +paramValue)[0];

      setLevelMenuId(activeLevelObject.id);
      setLevelMenuIdAll(false);
    }

    // Set selected training-type id if it's in params
    if (filterParams.has('type') && trainingType.length > 0) {
      const paramValue = filterParams.get('type');
      const activeTrainingTypeObject = trainingType.filter(({ id }) => +id === +paramValue)[0];

      setTrainingTypeMenuId(activeTrainingTypeObject.id);
      setTrainingTypeMenuIdAll(false);
    }

    // Set selected gender if it's in params
    if (filterParams.has('gender')) {
      const paramValue = filterParams.get('gender');
      const activeGenderObject = gender.filter(({ title }) => title === paramValue)[0];

      setGenderMenuId(activeGenderObject.id);
      setGenderMenuIdAll(false);
    }
  }, [trainers, gym, level, trainingType]);

  /*** Handlers ***/
  // Toggle side menu
  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setFilterSideMenu(!filterSideMenu);
  };

  // Получение отфильтрованных занятий
  const addFilterHandler1 = (filterType, id) => {
    // mark several selected gym
    if (filterType === 'gym__in') {
      setGymSelectedMob((prevState) => {
        if (prevState.split('').includes(id.toString())) {
          return prevState
            .split('')
            .filter((item) => item !== id.toString())
            .join('');
        }

        return prevState + id;
      });
    }

    // mark several selected type training
    if (filterType === 'type__in') {
      setTypeSelectedMob((prevState) => {
        if (prevState.split('').includes(id.toString())) {
          return prevState
            .split('')
            .filter((item) => item !== id.toString())
            .join('');
        }

        return prevState + id;
      });
    }

    // mark several selected trainer
    if (filterType === 'trainer__in') {
      setTrainerSelectedMob((prevState) => {
        if (prevState.split('').includes(id.toString())) {
          return prevState
            .split('')
            .filter((item) => item !== id.toString())
            .join('');
        }

        return prevState + id;
      });
    }

    // mark several selected level
    if (filterType === 'level__in') {
      setLevelSelectedMob((prevState) => {
        if (prevState.split('').includes(id.toString())) {
          return prevState
            .split('')
            .filter((item) => item !== id.toString())
            .join('');
        }

        return prevState + id;
      });
    }

    setIsResetFilterBtn(true); // Show reset filter btn

    // Если параметр этого фильтра с таким значением уже есть, то не делать никаких запросов
    if (filterParams.has(filterType) && filterParams.get(filterType).split(',').includes(id.toString())) {
      const filterTypeValues = filterParams.get(filterType).split(',');
      const we = filterTypeValues.filter((item) => +item !== id);
      const newParamsObj = {}; // Создать новый объект с параметрами

      // Взять существующие параметры и сформировать новый объект
      for (let [key, value] of filterParams.entries()) {
        if (key === filterType) {
          if (we.length > 0) {
            newParamsObj[key] = we.join(',');
          } else {
            if (filterType === 'gym__in') {
              setGymMenuIdAll(true);
            }
            if (filterType === 'type__in') {
              setTrainingTypeMenuIdAll(true);
            }
            if (filterType === 'trainer__in') {
              setTrainersMenuAll(true);
            }
            if (filterType === 'level__in') {
              setLevelMenuIdAll(true);
            }
          }
        } else {
          newParamsObj[key] = value;
        }
      }

      // Сформировать строку для запроса
      const paramsString = Object.entries(newParamsObj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      // Hide reset filter btn
      if (
        !Object.keys(newParamsObj).includes('gym__in') &&
        !Object.keys(newParamsObj).includes('type__in') &&
        !Object.keys(newParamsObj).includes('trainer__in') &&
        !Object.keys(newParamsObj).includes('level__in')
      ) {
        setIsResetFilterBtn(false);
      }

      setFilterParams(newParamsObj); // Установить параметры в адресную строку

      // Запрос для фильтрации
      api.get(`klass/?${paramsString}`).then(({ data }) => {
        const filteredClass = data.filter(({ is_published }) => is_published === true);

        data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

        setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
      });

      return;
    }

    const newParamsObj = {}; // Создать новый объект с параметрами

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    if (newParamsObj[filterType]) {
      newParamsObj[filterType] = `${newParamsObj[filterType]},${id}`;
    } else {
      newParamsObj[filterType] = id; // Добавить новый параметр в объекты фильтра
    }

    // Добавить новый параметр в объекты фильтра
    // newParamsObj[filterType] = id;

    // Сформировать строку для запроса
    const paramsString = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    setFilterParams(newParamsObj); // Установить параметры в адресную строку

    // Запрос для фильтрации
    api.get(`klass/?${paramsString}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };

  // Удаление параметра из фильтра занятий
  const deleteFilterHandler = (deleteParam) => {
    // Если параметра этого фильтра нет, то не делать никаких запросов
    if (!filterParams.has(deleteParam)) {
      return;
    }

    // Удаление параметра
    filterParams.delete(deleteParam);

    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      newParamsObj[key] = value;
    }

    // Hide reset filter btn
    if (Object.keys(newParamsObj).length === 2) {
      setIsResetFilterBtn(false);
    }

    // Сформировать строку для запроса
    const paramsString = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    // Установить параметры в адресную строку
    setFilterParams(newParamsObj);

    // Запрос для фильтрации
    api.get(`klass/?${paramsString}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };

  // Сброс фильтров
  const resetFilter = () => {
    // Создать новый объект с параметрами
    const newParamsObj = {};

    // Взять существующие параметры и сформировать новый объект
    for (let [key, value] of filterParams.entries()) {
      if (key === 'start_date' || key === 'end_date') newParamsObj[key] = value;
    }

    // Сформировать строку для запроса
    const paramsString = Object.entries(newParamsObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    setFilterParams(newParamsObj); // Установить параметры в адресную строку
    setGymMenuIdAll(true); // Set default gym value
    setGymSelectedMob(''); // Reset selected gym mark
    // setGymMenuId(null); // Set default gym id
    setTrainingTypeMenuIdAll(true); // Set default training type value
    setTypeSelectedMob(''); // Reset selected training type mark
    // setTrainingTypeMenuId(null); // Set default training type id
    setTrainersMenuAll(true); // Set default trainer value
    setTrainerSelectedMob(''); // Reset selected trainer mark
    // setTrainersMenuId(null); // Set default trainer id
    setLevelMenuIdAll(true); // Set default level value
    setLevelSelectedMob(''); // Reset selected level mark
    // setLevelMenuId(null); // Set default level id
    setIsResetFilterBtn(false); // Hide reset filter button

    // Запрос для фильтрации
    api.get(`klass/?${paramsString}`).then(({ data }) => {
      const filteredClass = data.filter(({ is_published }) => is_published === true);

      data.length === 0 ? setNoClassMsg('Занятий нет') : setNoClassMsg('');

      setScheduleInfo(createScheduleDataObj(filteredClass)); // Set klass data to state
    });
  };

  const selectedClass = (id, state) => (state === id ? 'selected' : ''); // Класс выбранного пункта

  return (
    <>
      <button className="filter-btn-mob" aria-label="filters" onClick={toggleDrawer}>
        Фильтры
      </button>

      <Drawer classes={{ paper: 'filter-menu-wrapper-mob' }} anchor="left" open={filterSideMenu} onClose={toggleDrawer}>
        <div className="filter-menu-mob" role="presentation">
          <div className="filter-menu-mob__heading">
            <h3 className="filter-menu-mob__title">Фильтры</h3>

            <div className="filter-menu-mob__close" onClick={toggleDrawer}>
              <CloseMenu />
            </div>
          </div>

          <div className="filter-menu-mob__body">
            {/* Gym */}
            <Accordion disableGutters square classes={{ root: 'filter-menu-mob__accordion' }}>
              <AccordionSummary expandIcon={<DropDownIcon />}>залы</AccordionSummary>

              <AccordionDetails>
                <ul className="filter-menu-mob__list">
                  <li
                    onClick={() => {
                      deleteFilterHandler('gym__in'); // Delete param
                      // setGymMenuId(null);
                      setGymSelectedMob('');
                      setGymMenuIdAll(true);
                    }}
                  >
                    <span className={`${gymMenuIdAll ? 'selected' : ''}`}>--- Все ---</span>
                  </li>

                  {gym.map(({ id, name, is_published }) => {
                    if (is_published) {
                      return (
                        <li
                          key={id}
                          className={`${gymSelectedMob?.includes(id.toString()) ? 'selected' : ''} `}
                          onClick={() => {
                            setGymMenuIdAll(false);
                            // setGymMenuId(id);
                            addFilterHandler1('gym__in', id);
                          }}
                        >
                          <span className={selectedClass(id, gymMenuId)}>{name}</span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>

            {/* Training type */}
            <Accordion disableGutters square classes={{ root: 'filter-menu-mob__accordion' }}>
              <AccordionSummary expandIcon={<DropDownIcon />}>тип тренировки</AccordionSummary>

              <AccordionDetails>
                <ul className="filter-menu-mob__list">
                  <li
                    onClick={() => {
                      deleteFilterHandler('type__in'); // Delete param
                      // setTrainingTypeMenuId(null);
                      setTypeSelectedMob('');
                      setTrainingTypeMenuIdAll(true);
                    }}
                  >
                    <span className={`${trainingTypeMenuIdAll ? 'selected' : ''}`}>--- Все ---</span>
                  </li>

                  {trainingType.map(({ id, title, is_published }) => {
                    if (is_published) {
                      return (
                        <li
                          key={id}
                          className={`${typeSelectedMob?.includes(id.toString()) ? 'selected' : ''} `}
                          onClick={() => {
                            setTrainingTypeMenuIdAll(false);
                            // setTrainingTypeMenuId(id);
                            addFilterHandler1('type__in', id); // Add filter param
                          }}
                        >
                          <span className={selectedClass(id, trainingTypeMenuId)}>{title}</span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>

            {/* Trainer */}
            <Accordion disableGutters square classes={{ root: 'filter-menu-mob__accordion' }}>
              <AccordionSummary expandIcon={<DropDownIcon />}>тренер</AccordionSummary>

              <AccordionDetails>
                <ul className="filter-menu-mob__list">
                  <li
                    onClick={() => {
                      deleteFilterHandler('trainer__in'); // Delete param
                      // setTrainersMenuId(null);
                      setTrainerSelectedMob('');
                      setTrainersMenuAll(true);
                    }}
                  >
                    <span className={`${trainersMenuAll ? 'selected' : ''}`}>--- Все ---</span>
                  </li>

                  {trainers.map(({ id, first_name, last_name }) => {
                    return (
                      <li
                        key={id}
                        className={`${trainerSelectedMob?.includes(id.toString()) ? 'selected' : ''} `}
                        onClick={() => {
                          setTrainersMenuAll(false);
                          // setTrainersMenuId(id);
                          addFilterHandler1('trainer__in', id);
                        }}
                      >
                        <span className={selectedClass(id, trainersMenuId)}>
                          {last_name} {first_name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>

            {/* Level */}
            <Accordion disableGutters square classes={{ root: 'filter-menu-mob__accordion' }}>
              <AccordionSummary expandIcon={<DropDownIcon />}>уровень</AccordionSummary>

              <AccordionDetails>
                <ul className="filter-menu-mob__list">
                  <li
                    onClick={() => {
                      deleteFilterHandler('level__in'); // Delete param
                      // setLevelMenuId(null);
                      setLevelSelectedMob('');
                      setLevelMenuIdAll(true);
                    }}
                  >
                    <span className={`${levelMenuIdAll ? 'selected' : ''}`}>--- Все ---</span>
                  </li>

                  {level.map(({ id, title, is_published }) => {
                    if (is_published) {
                      return (
                        <li
                          key={id}
                          className={`${levelSelectedMob?.includes(id.toString()) ? 'selected' : ''} `}
                          onClick={() => {
                            setLevelMenuIdAll(false);
                            // setLevelMenuId(id);
                            addFilterHandler1('level__in', id); // Add filter param
                          }}
                        >
                          <span className={selectedClass(id, levelMenuId)}>{title}</span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>

            {/* Gender */}
            {/* потом вернуть */}
            {/*<Accordion disableGutters square classes={{ root: 'filter-menu-mob__accordion' }}>*/}
            {/*  <AccordionSummary expandIcon={<DropDownIcon />}>гендер тренировки</AccordionSummary>*/}

            {/*  <AccordionDetails>*/}
            {/*    <ul className="filter-menu-mob__list">*/}
            {/*      <li*/}
            {/*        onClick={() => {*/}
            {/*          deleteFilterHandler('gender'); // Delete param*/}
            {/*          setGenderMenuId(null);*/}
            {/*          setGenderMenuIdAll(true);*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <span className={`${genderMenuIdAll ? 'selected' : ''}`}>--- Все ---</span>*/}
            {/*      </li>*/}

            {/*      {gender.map(({ id, title, name }) => {*/}
            {/*        return (*/}
            {/*          <li*/}
            {/*            key={id}*/}
            {/*            onClick={() => {*/}
            {/*              setGenderMenuIdAll(false);*/}
            {/*              setGenderMenuId(id);*/}
            {/*              addFilterHandler('gender', id, title); // Add filter param*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <span className={selectedClass(id, genderMenuId)}>{name}</span>*/}
            {/*          </li>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </ul>*/}
            {/*  </AccordionDetails>*/}
            {/*</Accordion>*/}
          </div>

          {/* Reset filter button */}
          {isResetFilterBtn && (
            <button className="reset-filter" onClick={resetFilter}>
              очистить фильтры
            </button>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default ScheduleFilterMob;
