import React, { useContext } from 'react';

// Files
import './TrainingHistoryClass.scss';
import { convertClassTime } from './index';
import { hexToRGB } from '../../../constants/constants';

// Components
import Image from '../../../components/Image/Image';

// Context
import { AddCommentPopupContext } from '../../../components/App/App';
import { SelectedClassContext } from '../TrainingHistoryPopup/TrainingHistoryPopup';

const TrainingHistoryClass = ({ data }) => {
  const { setAddCommentPopupOpen, setIsCommentPopupForm } = useContext(AddCommentPopupContext);
  const { setSelectedClassId } = useContext(SelectedClassContext);

  const monthList = [
    ['Янв'],
    ['Фев'],
    ['Мар'],
    ['Апр'],
    ['Май'],
    ['Июн'],
    ['Июл'],
    ['Авг'],
    ['Сен'],
    ['Окт'],
    ['Ноя'],
    ['Дек'],
  ];

  const { id, date, duration, level, type, trainer } = data;

  const classTime = `${convertClassTime(date, duration).startTime} - ${convertClassTime(date, duration).endTime}`;
  const trainingDateArr = date.split('T')[0].split('-'); // Массив даты занятия
  const trainingMonthName = monthList[trainingDateArr[1] - 1]; // Месяц занятия

  const trainingDate = `${trainingDateArr[2]} ${trainingMonthName} ${trainingDateArr[0]}`; // Дата занятия

  return (
    <div
      className="history-class"
      style={{ borderColor: level.color, boxShadow: `inset 3px -3px 23px ${hexToRGB(level.color, 0.7)}` }}
    >
      <div className="history-class__item history-class__date">{trainingDate}</div>
      <div className="history-class__item history-class__time">{classTime}</div>
      <div className="history-class__item history-class__training-type">{type}</div>

      {/*<div className="history-class__item history-class__image">*/}
      {/*  <Image desk={trainer.avatar} alt={'trainer_avatar'} title={'trainer_avatar'} />*/}
      {/*</div>*/}

      <div className="history-class__item history-class__trainer-name">{`${trainer.first_name} ${trainer.last_name}`}</div>
      {/*<div className="history-class__item history-class__trainer-rating">оценка 5</div>*/}

      {/*<button*/}
      {/*  className="history-class__item btn btn--bg history-class__btn"*/}
      {/*  onClick={() => {*/}
      {/*    setSelectedClassId(id); // установить выбранный id*/}
      {/*    setIsCommentPopupForm(true); // отобразить форму комментов*/}
      {/*    setAddCommentPopupOpen(true); // открыть попап комментов*/}
      {/*  }}*/}
      {/*>*/}
      {/*  написать отзыв*/}
      {/*</button>*/}
    </div>
  );
};

export default TrainingHistoryClass;
