import React from 'react';

// Files
import { apiHostName } from '../../constants/api';

const Image = ({ mob, desk, alt = '', title = '' }) => {
  return (
    <picture>
      <source type="image/webp" srcSet={`${apiHostName}${mob ? mob : desk}`} media="(max-width: 743px)" />
      <source type="image/webp" srcSet={`${apiHostName}${desk}`} media="(min-width: 744px)" />

      <img src={`${apiHostName}${desk}`} alt={alt} title={title} loading="lazy" />
    </picture>
  );
};

export default Image;
