import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Files
import logoImg from '../../assets/images/logo.png';
import { api } from '../../constants/api';
import './PopupAccount.scss';

// Components
import ClosePopup from '../IconComponents/ClosePopup';

// Context
import { AccountPopupContext, IsLoggedInContext } from '../App/App';

const PopupAccount = () => {
  const navigate = useNavigate();

  const { setIsLoggedIn } = useContext(IsLoggedInContext); // Use user state context
  const { isPopupAccountOpen, setIsPopupAccountOpen } = useContext(AccountPopupContext); // Use app account popup context

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  /*** Handlers ***/
  const handleClosePopup = () => setIsPopupAccountOpen(!isPopupAccountOpen);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    errors[name] = '';
    errors.noAccountError = '';
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    api
      .post('token/', formData)
      .then(({ data, status }) => {
        // обработка успешного ответа
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);

        if (status === 200) {
          api
            .get('get_my_id/')
            .then(({ data }) => localStorage.setItem('user', JSON.stringify(data)))
            .catch(({ response }) => {
              console.log(response);
            });

          setIsPopupAccountOpen(!isPopupAccountOpen);
          setIsLoggedIn(true);
        }
      })
      .catch((error) => {
        // не найден аккаунт
        if (error.response.status === 401) {
          setErrors({ noAccountError: 'Неверный логин или пароль, попробуйте снова' });
        }

        // неправильные/пустые логин и пароль
        if (error.response.status === 400) {
          setErrors(error.response.data);
        }

        console.error(error);
      });
  };

  return (
    <div className={`popup-account ${isPopupAccountOpen ? 'popup-account--open' : ''}`}>
      <div className="popup-account__wrapper">
        <Link className="popup-account__logo" to="/">
          <img src={logoImg} alt="logo" />
        </Link>

        <button className="popup-account__close" onClick={handleClosePopup}>
          <ClosePopup />
        </button>

        <form className="popup-form login-popup" onSubmit={handleSubmit}>
          <label htmlFor="username" className="popup__item popup__input-login">
            <input
              type="text"
              name="username"
              autoComplete="username"
              placeholder="Логин *"
              onChange={handleInputChange}
              className="popup__login"
              value={formData.username}
            />

            <div className={`error ${errors.username ? 'error_active' : ''}`}>{errors?.username}</div>
          </label>

          <label htmlFor="password" className="popup-form__item popup-form__input-password">
            <input
              type="password"
              name="password"
              placeholder="Пароль *"
              autoComplete="current-password"
              onChange={handleInputChange}
              value={formData.password}
            />
            <div className={`error ${errors.password ? 'error_active' : ''}`}>{errors?.password}</div>
          </label>

          <a onClick={handleClosePopup} className="popup-form__text" href="/recovery-password-email/">
            Забыли пароль?
          </a>

          {errors?.noAccountError && <div className="error error_active">{errors.noAccountError}</div>}

          <button type="submit" style={{ marginTop: 50 }} className="popup-form__btn">
            Вход
          </button>

          <button
            type="button"
            className="popup-form__btn popup-form__btn_black"
            onClick={() => {
              handleClosePopup();

              navigate('/registration');
            }}
          >
            Регистрация
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupAccount;
