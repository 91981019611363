import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { descriptionDivision } from '../../constants/constants';

// Files
import './TrainersPage.scss';
import { api } from '../../constants/api';
import { toggleBodyScrollHandler } from '../../constants/constants';

// Components
import TopCardsPreviewSlider from '../../components/TopCardsPreviewSlider/TopCardsPreviewSlider';
import SliderThumbsBottom from '../../components/SliderThumbsBottom/SliderThumbsBottom';
import Rating from '../../components/Rating/Rating';
import Review from '../../components/Review/Review';
import YMap from '../../components/YMap/YMap';
import CommentPopup from '../../components/CommentPopup/CommentPopup';

// Context
import { AccountPopupContext, AddCommentPopupContext, IsLoggedInContext } from '../../components/App/App';
export const SetTrainerIdContext = createContext({});

const TrainersPage = () => {
  // Use context
  const { isLoggedIn } = useContext(IsLoggedInContext);
  const { setIsPopupAccountOpen } = useContext(AccountPopupContext);
  const { setAddCommentPopupOpen, setIsCommentPopupForm } = useContext(AddCommentPopupContext);

  const trainerScrollRef = useRef(null);

  // Page
  const [pageInfo, setPageInfo] = useState({}); // Page title and subtitle

  // Trainers
  const [trainersList, setTrainersList] = useState([]); // Top slider with all trainers
  const [activeTrainerId, setActiveTrainerId] = useState(null); // Selected trainer id
  const [activeTrainer, setActiveTrainer] = useState([]); // Selected trainer data
  const [activeTrainerSlides, setActiveTrainerSlides] = useState([]); // Selected trainer slides
  const [activeTrainerReviews, setActiveTrainerReviews] = useState([]); // Selected trainer reviews
  const [descriptionAbout, setdescriptionAbout] = useState(null);
  const [readMore, setReadMore] = useState(false);


  // Get default page info and trainers data on page load
  useEffect(() => {
    // Set page info
    api.get('dynamic-page/trainers/').then(({ data }) => {
      const pageInfo = {
        title: data.title,
        description: data.blocks[0].content,
      };

      setPageInfo(pageInfo);
    });

    // Get order trainers and set ordered trainers data
    api.get('trainer-page/').then(({ data }) => {
      api.get(`trainers/?ordering=${data.ordering_trainers}`).then(({ data }) => {
        const filterSortedTrainers = data.filter(({ active }) => active === true);

        setTrainersList(filterSortedTrainers); // Set trainers list
        setActiveTrainerId(filterSortedTrainers[0].id); // Set the first active trainer id
      });
    });

    window.scrollTo({ top: 0, behavior: 'auto' }); // скролл в начало страницы
  }, []);

  // Change trainer
  useEffect(() => {
    setReadMore(false);
    if (activeTrainerId) {
      api.get(`trainers/${activeTrainerId}/`).then(({ data }) => {
        const filteredActiveTrainerReviews = data.trainer_reviews.filter(({ is_published }) => is_published === true);

        let countSymbols;
        if(typeof window !== 'undefined') {
          if(window.screen.width > 1109) {
            countSymbols = 500;
          } else if (window.screen.width > 1036 && window.screen.width < 1110) {
           countSymbols = 400;
          } else if(window.screen.width > 868 && window.screen.width < 1037) {countSymbols = 300;} else {countSymbols = 250;}
        }
        setdescriptionAbout (descriptionDivision(data.user.about, countSymbols, 'sentence'));
        setActiveTrainer(data); // Set trainer data
        setActiveTrainerSlides(data.trainer_slides); // Set active trainer slides
        setActiveTrainerReviews(filteredActiveTrainerReviews); // Set active trainer reviews
      });
    }
  }, [activeTrainerId]);

  return (
    <SetTrainerIdContext.Provider value={{ activeTrainerId, setActiveTrainerId }}>
      <div className="container">
        <h1 className="page-title page-title--trainers">{pageInfo.title}</h1>
        <p className="page-subtitle">{pageInfo.description}</p>

        {/*** Top cards preview slider ***/}
        <section className="trainer-types">
          <TopCardsPreviewSlider scrollTarget={trainerScrollRef} data={trainersList} />
        </section>

        {/*** Active trainer info ***/}
        <section className="trainer" ref={trainerScrollRef}>
          <div className="trainer__images">
            <SliderThumbsBottom slides={activeTrainerSlides} />
          </div>

          {/* Имя */}
          <div className="trainer__title">
            <p className="details-title">{`${activeTrainer.last_name} ${activeTrainer.first_name}`}</p>

            {/* временно скрыто */}
            {/*<div className="trainer__rating">*/}
            {/*  <Rating item={activeTrainer} />*/}
            {/*</div>*/}
          </div>

          {/* Опыт */}
          <div className="trainer__info-block experience">
            <span className="trainer__info-block-title">Игровой опыт</span>
            <p className="trainer__info-block-description">
              {activeTrainer?.user?.experience ? `${activeTrainer?.user?.experience} лет` : 'Не указано'}
            </p>
          </div>

          {/* О себе */}
          <div className="trainer__info-block about">
            <span className="trainer__info-block-title">О себе</span>
            <div className="trainer__info-block-description">
              {descriptionAbout ? descriptionAbout.map((item, index) => (
                <p key={index} className={(readMore && index > 0) || index == 0 ? 'trainer__info-block-description-visible' : 'trainer__info-block-description-hide'} dangerouslySetInnerHTML={{
                  __html: item.replaceAll('\n', '<br /><br />')
                }}></p>
              )) : 'Не указано'}
            </div>
            <div className="trainer__info-block readMore-container">
              {(descriptionAbout && descriptionAbout[1] !== '')? <button type='button' className='trainer__info-block-readMore' onClick={()=> setReadMore(!readMore)}>{!readMore ? 'Читать далее' : 'Свернуть'}</button> : false}
              </div>
          </div>

          {/* временно скрыто */}
          {/* Достижения */}
          {/* {activeTrainer?.achievement_trainer && (
            <div className="trainer__info-block achievements">
              <span className="trainer__info-block-title">Достижения</span>

              <div
                className="cke-description"
                dangerouslySetInnerHTML={{ __html: activeTrainer.achievement_trainer }}
              />
            </div>
          )} */}

          {/* К расписанию */}
          <Link to={`/schedule/?trainer__in=${activeTrainerId}`} className="btn btn--bg trainer__schedule">
            К расписанию
          </Link>
        </section>

        {/*** Trainer reviews ***/}
        {/* временно скрыто, потом надо будет вернуть */}
        {/*<section className="trainer-reviews">*/}
        {/*  <h2 className="page-title">Отзывы</h2>*/}

        {/*  {activeTrainerReviews.length > 0 ? (*/}
        {/*    <ul className="reviews">*/}
        {/*      {activeTrainerReviews.map((item) => {*/}
        {/*        return (*/}
        {/*          <li key={item.id} className="reviews__item">*/}
        {/*            <Review item={item} />*/}
        {/*          </li>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*    </ul>*/}
        {/*  ) : (*/}
        {/*    <p className="no-reviews">Отзывов для этого тренера нет</p>*/}
        {/*  )}*/}

        {/*  <button*/}
        {/*    className="btn btn--bg add-review"*/}
        {/*    onClick={() => {*/}
        {/*      if (!isLoggedIn) {*/}
        {/*        setIsPopupAccountOpen(true);*/}
        {/*        return;*/}
        {/*      }*/}

        {/*      toggleBodyScrollHandler();*/}

        {/*      setIsCommentPopupForm(true);*/}
        {/*      setAddCommentPopupOpen(true);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    написать отзыв*/}
        {/*  </button>*/}
        {/*</section>*/}
      </div>

      {/*** Comment popup ***/}
      <CommentPopup previousComments={activeTrainerReviews} target={'t'} targetId={activeTrainerId} />
    </SetTrainerIdContext.Provider>
  );
};

export default TrainersPage;
